<div class="icon-wrapper" [class.module-icon]="isModuleIcon">
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 425.2 425.2"
  >
    <g class="curtain">
      <path
        d="M303.646,350.54c-0.844,1.181-1.69,2.361-2.537,3.54c0.169-1.647,0.337-3.294,0.506-4.938
			C302.292,349.607,302.969,350.074,303.646,350.54z"
      />
      <path d="M167.481,275.481" />
      <path
        d="M100.581,250.804c-0.902-0.572-1.507-1.282-1.817-2.129c-0.309-0.846-0.132-1.718,0.538-2.613
			c1.253-1.675,3.191-2.089,5.786-1.24C103.263,247.215,102.363,248.409,100.581,250.804z"
      />
      <path
        d="M87.902,275.111c0.651-0.252,1.32-0.208,2.001,0.131c0.612,0.304,1.016,0.737,1.214,1.301
			c0.2,0.564,0.11,1.116-0.263,1.66c-0.581,0.849-1.811,1.518-3.685,2.005c-0.543-0.271-0.815-0.408-1.358-0.681
			c-0.059-1.506,0.133-2.591,0.581-3.253C86.747,275.75,87.252,275.363,87.902,275.111z"
      />
      <g>
        <path
          d="M114.021,386.089c17.757-29.181-10.699-43.283,4.498-52.133c15.199-8.851,74.664,3.195,102.537-20.241
				c27.874-23.438-29.85-26.097-13.369-46.938c16.478-20.839,68.239,26.306,84.109-11.739
				c15.869-38.047-30.317-42.615-17.646-54.595c12.672-11.98,47.444,9.956,93.676-19.722c46.232-29.676-58.589-36.897-26.926-55.116
				c31.663-18.221,67.359-55.726,38.935-92.25c-28.428-36.521-73.007,20.965-84.378,6.877c-11.373-14.088-8.721-25.902-25.277-25.05
				c-16.559,0.854-16.099,49.343-43.098,50.708c-27,1.366-29.499-24.356-59.029-23.688c-29.528,0.667-18.73,128.588-40.667,128.571
				c-21.936-0.02-32.017-34.964-63.044-18.829C33.315,168.08,73.25,276.043,65.65,281.251c-7.6,5.207-19.398-12.879-33.961-5.593
				s2.263,102.274,11.557,92.198c9.293-10.074,142.079-254.305,307.033-315.063C204.23,120.094,46.618,368.554,54.704,383.839
				C62.788,399.124,96.266,415.271,114.021,386.089z"
        />
      </g>
    </g>
    <g class="svg-type-font" [class.hide-type-font]="hideTypeFont">
      <path
        d="M55.184,358.705c-2.647,0.491-3.709,1.257-3.627,3.376c-0.005,1.453,0.179,3.608,0.755,8.035l2.497,19.185
		l-1.475,0.191l-23.525-22.306l-0.095,0.014l1.555,11.948c0.57,4.378,1.01,6.646,1.333,8.008c0.554,2.396,1.723,2.825,4.985,2.593
		l0.173,1.334l-11.282,1.468l-0.173-1.333c2.743-0.502,3.852-1.276,3.786-3.639c-0.049-1.494-0.204-3.798-0.774-8.177l-1.362-10.473
		c-0.452-3.477-0.572-3.654-1.578-4.686c-1.106-1.064-2.203-1.311-4.466-1.209l-0.173-1.333l7.045-0.917l22.961,21.313l0.142-0.019
		l-1.523-11.709c-0.571-4.38-1.045-6.546-1.362-7.859c-0.517-2.111-1.74-2.582-5.05-2.345l-0.173-1.332l11.234-1.463L55.184,358.705
		z"
      />
      <path
        class="text-overlap"
        d="M75.58,386.742c-0.714,0.093-1.77-0.206-2.355-0.612c-0.706-0.587-1.078-1.217-1.42-1.994
		c-1.737,1.532-3.793,3.592-5.221,3.777c-3.379,0.439-6.078-2.065-6.468-5.064c-0.304-2.333,0.742-3.968,3.243-5.214
		c2.732-1.324,6.04-3.062,6.975-4.056l-0.111-0.856c-0.446-3.427-2.319-5.169-4.699-4.858c-0.952,0.123-1.617,0.598-2.018,1.231
		c-0.538,0.699-0.831,1.802-1.02,3.327c-0.091,0.786-0.571,1.188-1.238,1.274c-0.857,0.111-2.218-0.679-2.361-1.774
		c-0.093-0.714,0.458-1.317,1.275-2.102c1.209-1.125,4.061-3.386,6.707-4.262c1.428-0.187,2.913,0.057,4.071,0.777
		c1.846,1.163,2.576,3.053,2.898,5.529l1.152,8.853c0.279,2.144,1.229,2.744,2.038,2.64c0.571-0.074,1.207-0.398,1.645-0.746
		l0.638,1.175L75.58,386.742z M70.543,375.199c-0.932,0.652-3.087,1.949-4.024,2.556c-1.745,1.099-2.674,2.14-2.452,3.853
		c0.323,2.477,2.381,3.418,3.857,3.227c1.231-0.209,2.792-1.236,3.585-2.209L70.543,375.199z"
      />
      <path
        class="text-overlap"
        d="M89.873,384.252c-0.753,0.534-1.49,0.823-1.918,0.88c-3,0.391-4.914-1.298-5.403-5.057l-1.802-13.854
		l-3.285,0.429l-0.253-0.452l1.106-1.547l2.189-0.284l-0.452-3.476l2.685-3.545l0.679,0.01l0.849,6.52l5.379-0.699
		c0.49,0.422,0.483,1.485-0.044,1.894l-5.093,0.663l1.592,12.234c0.502,3.855,2.166,4.365,3.355,4.21
		c1.19-0.154,2.223-0.772,2.846-1.192l0.589,1.182L89.873,384.252z"
      />
      <path
        class="text-overlap"
        d="M117.481,379.546c-2.146,0.62-4.487,1.602-6.774,2.625l-0.322-0.248l-0.44-3.38
		c-0.989,0.952-1.973,1.95-3.2,2.934c-1.275,0.989-2.226,1.499-3.51,1.667c-3.189,0.414-6.157-1.184-6.826-6.325l-1.214-9.33
		c-0.34-2.617-0.556-2.783-3.338-2.954l-0.142-1.095c2.219-0.434,4.467-1.017,6.696-1.741c0.049,1.493,0.34,3.73,0.761,6.967
		l0.831,6.379c0.551,4.236,2.747,5.112,4.889,4.834c1.714-0.224,3.43-1.173,4.844-2.954l-1.443-11.091
		c-0.353-2.714-0.664-2.868-4.153-2.897l-0.143-1.095c2.457-0.465,4.907-0.978,7.363-1.829l2.068,15.899
		c0.31,2.381,0.734,2.664,2.555,2.523l1.345-0.078L117.481,379.546z"
      />
      <path
        d="M124.833,363.146c1.119-2.565,2.819-5.497,5.104-5.795c1.619-0.211,2.922,0.879,3.07,2.022
		c0.125,0.951-0.371,1.983-1.219,2.529c-0.54,0.312-0.975,0.32-1.297,0.072c-0.759-0.629-1.368-0.839-2.129-0.738
		c-1.238,0.16-2.575,1.786-3.275,4.587l1.133,8.714c0.409,3.14,0.684,3.396,4.386,3.204l0.162,1.236l-10.949,1.426l-0.161-1.237
		c2.915-0.67,3.21-0.999,2.801-4.141l-1.282-9.854c-0.409-3.143-0.672-3.303-3.435-3.33l-0.137-1.046
		c2.283-0.685,4.304-1.53,6.458-2.826l0.675,5.188L124.833,363.146z"
      />
      <path
        d="M147.639,361.341c-1.27-2.69-3.184-4.378-5.516-4.074c-1.809,0.235-2.909,1.831-2.667,3.688
		c0.278,2.143,2.325,2.989,4.585,3.614c3.762,1.012,6.413,2.409,6.84,5.694c0.564,4.331-3.191,7.096-7,7.591
		c-2.427,0.316-4.735-0.303-5.685-0.906c-0.42-0.622-1.27-3.804-1.493-5.518l1.207-0.399c1.266,3.03,3.767,5.512,6.623,5.14
		c1.762-0.229,3.207-1.771,2.947-3.771c-0.272-2.095-1.802-3.058-4.325-3.844c-3.03-0.96-6.328-2.128-6.805-5.794
		c-0.434-3.332,2.057-6.513,6.437-7.082c1.952-0.253,3.292,0.007,4.342,0.258c0.524,1.045,1.412,3.786,1.71,4.957L147.639,361.341z"
      />
      <path
        d="M162.586,353.829c0.794-0.587,1.668-0.943,2.24-1.018c5.284-0.688,8.983,3.188,9.596,7.9
		c0.936,7.188-4.128,12.542-10.168,14.491c-1.387,0.132-2.734-0.178-3.396-0.431l0.812,6.236c0.471,3.619,0.834,3.812,4.591,3.663
		l0.167,1.285l-11.283,1.468l-0.167-1.284c3.01-0.685,3.401-1.023,2.992-4.167l-2.8-21.516c-0.402-3.093-0.605-3.164-3.606-3.161
		l-0.149-1.144c1.957-0.593,4.517-1.749,6.636-2.944l0.489,3.761L162.586,353.829z M160.424,371.441
		c1.191,0.958,3.098,1.483,4.716,1.272c3.999-0.519,6.218-4.293,5.519-9.674c-0.663-5.093-3.938-7.571-7.413-7.119
		c-1.523,0.199-3.339,1.499-4.5,2.619L160.424,371.441z"
      />
      <path
        d="M178.744,372.735l-0.162-1.237c2.962-0.676,3.258-1.005,2.837-4.241l-1.276-9.808
		c-0.384-2.951-0.552-3.122-3.47-3.228l-0.149-1.142c2.378-0.695,4.536-1.607,6.519-2.736l2.137,16.423
		c0.421,3.237,0.792,3.479,3.875,3.368l0.162,1.238L178.744,372.735z M177.63,344.465c-0.186-1.428,0.82-2.624,2.105-2.791
		c1.333-0.175,2.517,0.737,2.703,2.165c0.167,1.285-0.731,2.564-2.112,2.743C179.089,346.743,177.798,345.751,177.63,344.465z"
      />
      <path
        d="M208.252,364.78c-2.509,4.151-5.683,5.437-7.158,5.628c-5.854,0.762-9.901-3.553-10.602-8.932
		c-0.428-3.283,0.385-6.343,2.058-8.739c1.667-2.443,4.07-4.063,6.546-4.386c3.951-0.514,7.599,2.595,8.082,6.307
		c0.124,0.951-0.065,1.363-0.897,1.666c-0.791,0.248-6.679,1.498-12.318,2.426c0.89,6.469,4.95,8.65,8.282,8.216
		c1.903-0.247,3.613-1.243,5.116-3.085L208.252,364.78z M193.946,356.769c2.666-0.349,5.278-0.736,8.027-1.19
		c0.856-0.111,1.104-0.436,1.018-1.102c-0.274-2.481-2.19-4.556-4.808-4.215C195.992,350.546,194.158,352.818,193.946,356.769z"
      />
      <path
        d="M210.729,368.574l-0.161-1.238c2.961-0.676,3.352-1.017,2.943-4.159l-2.93-22.516
		c-0.421-3.238-0.726-3.343-3.755-3.191l-0.149-1.142c2.438-0.607,5.284-1.802,6.828-2.583l3.767,28.942
		c0.409,3.142,0.827,3.378,3.911,3.267l0.161,1.237L210.729,368.574z"
      />
      <path
        d="M241.672,343.731c-0.33,0.816-1.121,2.179-1.827,2.706l-2.732,0.211c1.041,0.93,1.759,2.336,1.981,4.05
		c0.668,5.141-3.294,8.174-7.341,8.7c-0.809,0.105-1.685,0.075-2.459,0.079c-0.609,0.516-1.261,1.471-1.173,2.137
		c0.124,0.952,1.098,1.746,3.24,1.467c1.714-0.224,3.422-0.494,4.993-0.698c2.95-0.384,6.977,0.061,7.585,4.726
		c0.624,4.808-4.344,10.148-10.675,10.974c-5.284,0.687-8.591-2.028-8.99-4.735c-0.137-1.047,0.175-2.008,0.795-2.815
		c0.827-1.076,2.486-2.841,3.518-3.846c-1.782-0.302-3.114-1.241-3.863-2.16c-0.521-0.658-0.77-1.449-0.784-1.932
		c1.811-0.962,3.174-2.396,3.811-3.448c-2.26-0.626-4.599-2.598-5.019-5.834c-0.707-5.426,3.827-8.534,7.493-9.011
		c1.617-0.21,3.245,0.014,4.725,0.596c2.32-0.399,4.71-1,6.387-1.51L241.672,343.731z M229.593,367.717
		c-1.108,1.16-1.888,2.617-1.707,3.997c0.335,2.571,3.35,4.163,6.873,3.704c4.521-0.587,6.55-3.225,6.167-6.174
		c-0.211-1.62-1.175-2.705-2.631-3.097c-1.251-0.321-2.65-0.283-4.648-0.023C231.36,366.422,230.35,366.842,229.593,367.717z
		 M226.728,352.019c0.482,3.713,2.638,6.142,5.304,5.796c2.089-0.32,3.703-2.419,3.213-6.18c-0.488-3.761-2.597-6.197-5.311-5.845
		C227.84,346.063,226.243,348.304,226.728,352.019z"
      />
      <path
        d="M251.155,346.707c1.12-2.564,2.82-5.497,5.104-5.794c1.62-0.212,2.923,0.879,3.072,2.021
		c0.123,0.95-0.372,1.984-1.221,2.529c-0.54,0.313-0.975,0.321-1.296,0.072c-0.762-0.628-1.368-0.838-2.131-0.739
		c-1.238,0.161-2.575,1.787-3.275,4.589l1.134,8.711c0.408,3.143,0.684,3.397,4.387,3.205l0.16,1.238l-10.948,1.425l-0.161-1.236
		c2.915-0.67,3.211-0.999,2.801-4.141l-1.282-9.854c-0.408-3.144-0.672-3.303-3.434-3.331l-0.136-1.046
		c2.282-0.686,4.303-1.529,6.457-2.825l0.675,5.188L251.155,346.707z"
      />
      <path
        d="M287.214,357.459c-2.147,0.618-4.487,1.601-6.774,2.624l-0.325-0.249l-0.439-3.379
		c-0.988,0.951-1.971,1.95-3.198,2.933c-1.275,0.99-2.227,1.501-3.512,1.668c-3.19,0.415-6.158-1.183-6.827-6.325l-1.212-9.33
		c-0.342-2.619-0.556-2.784-3.339-2.954l-0.143-1.096c2.22-0.434,4.468-1.017,6.697-1.742c0.048,1.496,0.34,3.732,0.761,6.968
		l0.83,6.381c0.552,4.235,2.748,5.111,4.89,4.833c1.715-0.223,3.429-1.173,4.842-2.954l-1.442-11.091
		c-0.353-2.715-0.662-2.867-4.152-2.897l-0.143-1.095c2.456-0.465,4.907-0.978,7.361-1.829l2.07,15.899
		c0.31,2.38,0.733,2.664,2.555,2.523l1.346-0.08L287.214,357.459z"
      />
      <path
        d="M297.144,336.318c0.795-0.587,1.668-0.942,2.239-1.018c5.285-0.688,8.983,3.188,9.597,7.9
		c0.936,7.188-4.129,12.542-10.168,14.49c-1.387,0.133-2.733-0.176-3.397-0.429l0.812,6.236c0.47,3.618,0.836,3.812,4.591,3.662
		l0.167,1.285l-11.282,1.468l-0.166-1.285c3.009-0.683,3.4-1.022,2.992-4.166l-2.802-21.516c-0.402-3.093-0.604-3.164-3.604-3.162
		l-0.149-1.142c1.956-0.593,4.517-1.75,6.637-2.944l0.489,3.76L297.144,336.318z M294.982,353.93
		c1.189,0.959,3.098,1.486,4.714,1.274c4.001-0.521,6.22-4.295,5.52-9.675c-0.663-5.093-3.938-7.57-7.413-7.118
		c-1.524,0.199-3.339,1.499-4.5,2.619L294.982,353.93z"
      />
      <path
        d="M321.562,333.141c0.795-0.587,1.668-0.943,2.239-1.018c5.284-0.687,8.982,3.188,9.596,7.9
		c0.937,7.188-4.127,12.543-10.167,14.491c-1.386,0.132-2.733-0.178-3.396-0.431l0.812,6.237c0.472,3.618,0.835,3.812,4.592,3.662
		l0.168,1.285l-11.282,1.468l-0.168-1.284c3.01-0.684,3.401-1.023,2.993-4.167l-2.8-21.516c-0.404-3.093-0.606-3.165-3.607-3.161
		l-0.148-1.144c1.956-0.593,4.516-1.748,6.636-2.944l0.489,3.762L321.562,333.141z M319.4,350.753
		c1.189,0.959,3.097,1.483,4.716,1.273c3.998-0.52,6.219-4.294,5.519-9.674c-0.663-5.094-3.938-7.572-7.414-7.118
		c-1.522,0.197-3.339,1.498-4.5,2.618L319.4,350.753z"
      />
      <path
        d="M355.469,345.622c-2.507,4.151-5.681,5.436-7.157,5.628c-5.854,0.762-9.9-3.553-10.601-8.932
		c-0.429-3.284,0.385-6.343,2.057-8.74c1.667-2.442,4.07-4.062,6.546-4.385c3.951-0.514,7.599,2.595,8.082,6.308
		c0.124,0.95-0.065,1.363-0.897,1.665c-0.79,0.247-6.678,1.499-12.318,2.425c0.891,6.469,4.951,8.65,8.282,8.217
		c1.903-0.247,3.613-1.243,5.116-3.086L355.469,345.622z M341.165,337.609c2.664-0.347,5.277-0.735,8.026-1.188
		c0.854-0.113,1.104-0.436,1.017-1.103c-0.274-2.481-2.19-4.557-4.807-4.216C343.211,331.389,341.377,333.661,341.165,337.609z"
      />
    </g>
  </svg>
</div>


