<div class="icon-wrapper">
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 425.2 425.2"
  >
    <g class="curtain">
      <path
        d="M106.258,85.714c-12.381-0.108-22.504,10.685-22.609,24.108
	c-0.104,13.424,9.85,24.394,22.23,24.502c12.382,0.108,22.504-10.686,22.609-24.109C128.592,96.791,118.64,85.822,106.258,85.714z
	 M105.904,131.12c-9.74-0.085-17.57-8.286-17.492-18.318c0.079-10.03,8.037-18.093,17.775-18.008
	c9.738,0.087,17.568,8.287,17.491,18.318C123.601,123.142,115.643,131.205,105.904,131.12z"
      />
      <path
        d="M232.432,42.486c-16.589-1.982-31.604,11.145-33.538,29.323
	c-1.933,18.178,9.95,34.52,26.54,36.503c16.59,1.982,31.606-11.145,33.539-29.322C260.905,60.812,249.021,44.47,232.432,42.486z
	 M225.896,103.972c-13.048-1.56-22.456-13.835-21.012-27.418c1.444-13.583,13.193-23.332,26.242-21.771
	c13.047,1.56,22.454,13.836,21.01,27.42C250.693,95.786,238.944,105.531,225.896,103.972z"
      />
      <path
        d="M353.179,101.442c-12.382-0.108-22.506,10.685-22.608,24.108
	c-0.106,13.424,9.849,24.394,22.228,24.502c12.383,0.109,22.506-10.685,22.608-24.108
	C375.513,112.521,365.559,101.551,353.179,101.442z M352.824,146.848c-9.741-0.086-17.57-8.286-17.49-18.318
	c0.078-10.03,8.034-18.092,17.772-18.007c9.74,0.086,17.569,8.286,17.492,18.317C370.521,138.871,362.563,146.934,352.824,146.848z"
      />
      <path
        d="M67.436,349.987c118.967,39.641,211.204,21.248,290.046-3.766
	C333.69,382.009,102.969,385.644,67.436,349.987z"
      />
      <path
        d="M67.436,349.987c-14.764-14.828-1.832-116.171-1.832-116.171s43.459,69.298,31.791,82.905
	c-11.667,13.609-29.568,31.926-29.568,31.926S67.941,348.946,67.436,349.987z"
      />
      <path
        d="M64.258,202.188c-7.485-0.065-13.612,7.454-13.686,16.795
	c-0.072,9.342,5.938,16.967,13.42,17.033c7.486,0.065,13.612-7.453,13.685-16.795C77.75,209.879,71.74,202.254,64.258,202.188z
	 M64.012,233.786c-5.889-0.053-10.616-5.752-10.562-12.732c0.056-6.979,4.872-12.597,10.76-12.546
	c5.886,0.052,10.613,5.753,10.559,12.734C74.714,228.221,69.898,233.839,64.012,233.786z"
      />
      <path
        d="M355.358,347.304c16.124-13.137,13.117-115.351,13.117-115.351
	s-49.965,64.175-39.685,78.987c10.282,14.812,26.309,34.988,26.309,34.988S354.954,346.212,355.358,347.304z"
      />
      <path
        d="M357.886,216.116c-0.837,9.299,4.528,17.448,11.979,18.201s14.168-6.176,15.005-15.475
	c0.834-9.3-4.53-17.449-11.978-18.203C365.441,199.888,358.722,206.815,357.886,216.116z M360.584,218.441
	c0.62-6.949,5.879-12.101,11.74-11.509c5.861,0.593,10.104,6.706,9.48,13.655s-5.88,12.102-11.743,11.51
	C364.203,231.505,359.957,225.391,360.584,218.441z"
      />
      <path
        d="M66.967,349.989c-0.021,0-0.043,0-0.064,0C66.922,349.989,66.947,349.989,66.967,349.989z"
      />
      <path
        d="M67.436,349.987c0,0-0.031,0-0.072,0C67.39,349.987,67.408,349.987,67.436,349.987z"
      />
      <path
        d="M66.902,349.989c-0.129,0.002-0.232,0.002-0.334,0.002
	C66.681,349.991,66.795,349.991,66.902,349.989z"
      />
      <path
        d="M67.363,349.987c-0.146,0.002-0.275,0.002-0.396,0.002
	C67.14,349.989,67.283,349.989,67.363,349.987z"
      />
      <path
        d="M66.568,349.991c-0.172,0.002-0.338,0.002-0.467,0.002
	C66.21,349.993,66.371,349.993,66.568,349.991z"
      />
      <path
        d="M66.102,349.993C65.732,349.995,65.832,349.995,66.102,349.993L66.102,349.993z"
      />
      <path
        d="M221.297,243.291c-12.382-0.108-22.506,10.685-22.608,24.108
	c-0.106,13.424,9.849,24.395,22.228,24.502c12.383,0.109,22.506-10.686,22.608-24.108
	C243.631,254.369,233.677,243.399,221.297,243.291z M220.942,288.696c-9.741-0.086-17.57-8.285-17.49-18.317
	c0.078-10.03,8.034-18.093,17.772-18.007c9.74,0.086,17.569,8.286,17.492,18.317C238.64,280.72,230.682,288.782,220.942,288.696z"
      />
      <path
        d="M127.43,267.34c-7.835,1.101-13.211,8.88-12.008,17.373
		c1.201,8.495,8.525,14.487,16.363,13.386c7.837-1.102,13.213-8.881,12.01-17.373C142.592,272.231,135.267,266.238,127.43,267.34z"
      />
      <path
        d="M344.415,323.851c16.054,2.301,5.194-173.508,4.619-175.513
		c0.079-0.143,0.103-0.204-0.054-0.17c-0.182-0.572-0.237-0.19-0.252,0.162c-1.23,0.767-64.389,90.342-76.533,131.616
		c-3.02-46.055-43.539-173.122-44.922-174.937c0.042-0.172-0.006-0.291-0.13-0.154c-0.521-0.622-0.385-0.115-0.233,0.29
		c-1.004,1.325-52.954,117.584-59.844,168.663c-11.297-45.199-59.484-141.658-60.64-142.826
		c0.011-0.15-36.376,203.822-10.947,186.498c0,0-25.97,22.375-28.044,32.508c41.617-45.404,216.465-47.051,264.833-17.549
		C376.493,359.409,351.612,339.583,344.415,323.851z M132.169,300.813c-9.96,1.4-19.343-6.68-20.95-18.047
		c-1.611-11.367,5.161-21.717,15.123-23.117c9.961-1.4,19.343,6.68,20.951,18.047C148.904,289.063,142.132,299.413,132.169,300.813z
		 M120.809,219.103c-13.334-4.343-21.424,50.324-21.424,50.324s-4.576-94.667,11.424-101.334s37.775,79.666,37.775,79.666
		S134.143,223.445,120.809,219.103z M226.143,125.748c13.332,3.679,24.26,101.528,24.26,101.528s-9.887-54.083-24.407-54.083
		s-37.187,58.76-37.187,58.76S212.809,122.069,226.143,125.748z M251.666,266.165c-0.426,18.274-14.309,32.596-31.006,31.988
		c-16.696-0.607-29.887-15.914-29.461-34.189c0.428-18.275,14.311-32.597,31.006-31.989
		C238.902,232.584,252.094,247.89,251.666,266.165z M329.517,289.19c-1.578,11.373-11.061,19.443-21.18,18.027
		c-10.114-1.414-17.038-11.781-15.456-23.152c1.578-11.373,11.062-19.443,21.179-18.027
		C324.175,267.454,331.098,277.819,329.517,289.19z M329.048,229.222c-10.872-8.857-29.39,24.205-29.39,24.205
		s21.121-74.939,38.449-75.39s1.098,91.95,1.098,91.95S339.92,238.079,329.048,229.222z"
      />

      <path
        d="M314.152,272.504c-7.701-1.813-15.531,3.488-17.488,11.84
	c-1.96,8.353,2.695,16.593,10.398,18.407c7.704,1.812,15.534-3.488,17.49-11.84C326.511,282.558,321.855,274.317,314.152,272.504z"
      />
    </g>
    <g class="svg-type-font">
      <path
        d="M120.776,333.542c-1.173-2.967-2.752-6.135-6.906-5.709c-3.678,0.377-5.027,3.217-4.763,5.796
		c0.357,3.485,2.815,4.874,6.789,6.446c3.921,1.527,8.688,3.307,9.235,8.656c0.514,5.014-3.325,9.461-9.676,10.111
		c-1.958,0.201-3.525-0.024-4.754-0.236c-1.237-0.307-1.988-0.567-2.442-0.762c-0.485-0.965-1.526-5.008-2.048-7.271l1.351-0.476
		c1.062,2.835,3.706,7.438,8.624,6.935c3.582-0.367,5.5-2.833,5.144-6.318c-0.362-3.535-3.16-4.937-6.938-6.479
		c-3.223-1.311-8.29-3.204-8.844-8.601c-0.47-4.584,2.806-8.877,8.966-9.508c2.197-0.225,4.257,0.095,6.129,0.481
		c0.368,1.7,0.798,3.538,1.488,6.507L120.776,333.542z"
      />
      <path
        d="M147.412,350.501c-1.817,2.984-4.801,5.414-7.619,5.703c-6.017,0.617-10.05-3.893-10.573-9.002
		c-0.377-3.678,0.871-7.037,3.816-9.849c2.286-2.165,5.073-3.223,6.936-3.413c1.911-0.196,3.551,0.263,4.378,0.806
		c0.675,0.462,0.861,0.876,0.916,1.401c0.103,1.003-0.841,2.161-1.366,2.215c-0.238,0.024-0.492-0.094-0.961-0.432
		c-1.569-1.191-3.305-1.641-4.976-1.469c-3.152,0.322-5.652,3.233-5.139,8.247c0.681,6.638,5.442,8.37,8.116,8.095
		c2.054-0.21,3.57-0.944,5.558-3.223L147.412,350.501z"
      />
      <path
        d="M162.702,353.276l-0.127-1.241c2.74-0.571,3.093-0.896,2.774-4l-0.812-7.927
		c-0.401-3.916-2.082-5.723-5.282-5.395c-1.814,0.186-3.536,1.279-4.716,2.945l1.165,11.365c0.312,3.056,0.586,3.365,3.433,3.363
		l0.127,1.241l-10.124,1.038l-0.127-1.242c2.841-0.532,3.241-0.863,2.918-4.014l-2.335-22.779c-0.309-3.009-0.428-3.237-3.609-3.201
		l-0.112-1.098c2.258-0.57,5.045-1.627,6.849-2.391l1.645,16.044c1.495-1.89,3.893-3.921,6.472-4.186
		c3.868-0.396,6.84,1.761,7.428,7.491l0.856,8.356c0.322,3.151,0.687,3.403,3.528,3.354l0.127,1.241L162.702,353.276z"
      />
      <path
        d="M174.733,352.044l-0.127-1.242c2.979-0.596,3.379-0.926,3.056-4.077l-2.315-22.588
		c-0.333-3.247-0.634-3.36-3.667-3.292l-0.117-1.146c2.454-0.541,5.331-1.656,6.896-2.396l2.976,29.034
		c0.323,3.151,0.734,3.399,3.82,3.372l0.127,1.242L174.733,352.044z"
      />
      <path
        d="M207.578,337.771c0.788,7.688-4.47,11.943-9.197,12.428c-6.542,0.671-10.996-4.181-11.54-9.482
		c-0.808-7.879,4.938-12.086,9.283-12.531C202.046,327.577,206.972,331.849,207.578,337.771z M191.125,338.733
		c0.617,6.018,3.704,10.237,7.619,9.836c2.961-0.304,5.172-2.75,4.501-9.293c-0.573-5.587-3.282-9.894-7.437-9.468
		C192.848,330.112,190.572,333.337,191.125,338.733z"
      />
      <path
        d="M222.927,331.71c-1.195-2.725-3.063-4.463-5.402-4.224c-1.815,0.187-2.958,1.751-2.768,3.612
		c0.221,2.149,2.243,3.053,4.485,3.739c3.733,1.113,6.345,2.583,6.683,5.878c0.445,4.346-3.383,7.006-7.203,7.398
		c-2.436,0.249-4.726-0.434-5.658-1.061c-0.403-0.636-1.166-3.838-1.342-5.558l1.217-0.366c1.183,3.064,3.616,5.613,6.48,5.32
		c1.768-0.182,3.254-1.686,3.049-3.691c-0.216-2.101-1.719-3.105-4.219-3.958c-3.002-1.044-6.268-2.302-6.645-5.979
		c-0.343-3.343,2.233-6.454,6.627-6.904c1.958-0.2,3.291,0.097,4.333,0.376c0.495,1.059,1.31,3.822,1.574,5.002L222.927,331.71z"
      />
      <path
        d="M240.212,329.938c-1.195-2.724-3.062-4.463-5.402-4.223c-1.814,0.186-2.958,1.75-2.768,3.612
		c0.221,2.148,2.244,3.052,4.486,3.738c3.732,1.113,6.344,2.584,6.682,5.878c0.445,4.347-3.383,7.007-7.203,7.398
		c-2.436,0.249-4.726-0.433-5.658-1.061c-0.403-0.636-1.165-3.838-1.342-5.558l1.218-0.365c1.182,3.063,3.615,5.613,6.48,5.32
		c1.767-0.182,3.253-1.686,3.048-3.691c-0.216-2.102-1.719-3.105-4.218-3.959c-3.003-1.044-6.269-2.301-6.646-5.979
		c-0.342-3.342,2.234-6.453,6.627-6.904c1.958-0.2,3.292,0.097,4.333,0.376c0.495,1.06,1.31,3.823,1.575,5.002L240.212,329.938z"
      />
      <path
        d="M276.478,321.733c-2.348,0.626-2.719,1.244-3.452,3.972c-1.046,3.921-2.497,10.486-4.027,17.206
		l-1.337,0.138c-2.438-4.962-4.877-9.44-7.349-14.255c-1.325,4.962-2.794,9.938-4.112,15.43l-1.385,0.142
		c-2.302-5.507-4.694-10.955-7.239-16.485c-1.114-2.395-1.578-2.686-3.905-2.784l-0.122-1.194l9.885-1.013l0.122,1.194
		c-2.815,0.771-3.025,1.082-2.088,3.156c1.475,3.564,3.055,7.216,4.626,10.771l0.095-0.01c1.298-4.766,2.56-9.866,4.003-15.563
		l1.242-0.127c2.275,4.785,4.753,9.646,7.282,14.551l0.096-0.01c0.713-3.403,1.623-7.693,2.121-11.316
		c0.366-2.547,0.05-2.805-3.098-2.915l-0.123-1.194l8.644-0.886L276.478,321.733z"
      />
      <path
        d="M299.066,328.394c0.788,7.688-4.47,11.942-9.197,12.428c-6.543,0.67-10.997-4.182-11.54-9.482
		c-0.808-7.879,4.938-12.088,9.283-12.533C293.533,318.199,298.46,322.471,299.066,328.394z M282.613,329.354
		c0.616,6.018,3.703,10.238,7.619,9.837c2.961-0.304,5.171-2.75,4.5-9.293c-0.572-5.587-3.282-9.894-7.437-9.468
		C284.335,320.734,282.06,323.96,282.613,329.354z"
      />
      <path
        class="text-overlap"
        d="M321.409,332.665c-1.816,2.985-4.801,5.414-7.619,5.703c-6.017,0.617-10.05-3.892-10.573-9.002
		c-0.377-3.677,0.871-7.037,3.815-9.849c2.287-2.165,5.074-3.223,6.937-3.413c1.91-0.196,3.55,0.264,4.378,0.806
		c0.675,0.462,0.861,0.876,0.916,1.402c0.103,1.003-0.841,2.16-1.365,2.214c-0.239,0.025-0.492-0.094-0.962-0.432
		c-1.569-1.19-3.305-1.641-4.976-1.469c-3.152,0.322-5.652,3.234-5.139,8.248c0.681,6.638,5.442,8.369,8.116,8.095
		c2.054-0.21,3.571-0.944,5.558-3.223L321.409,332.665z"
      />
      <path
        class="text-overlap"
        d="M336.699,335.44l-0.127-1.241c2.74-0.57,3.093-0.896,2.774-3.999l-0.812-7.928
		c-0.401-3.916-2.082-5.723-5.282-5.395c-1.814,0.187-3.536,1.279-4.717,2.945l1.165,11.365c0.313,3.057,0.586,3.365,3.434,3.363
		l0.127,1.242l-10.124,1.037l-0.127-1.242c2.841-0.531,3.241-0.862,2.918-4.014l-2.335-22.779c-0.309-3.008-0.428-3.236-3.609-3.201
		l-0.112-1.098c2.258-0.57,5.045-1.627,6.849-2.391l1.645,16.045c1.495-1.891,3.893-3.922,6.472-4.186
		c3.868-0.396,6.839,1.76,7.427,7.49l0.856,8.357c0.323,3.15,0.687,3.402,3.528,3.354l0.127,1.241L336.699,335.44z"
      />
      <path
        class="text-overlap"
        d="M366.031,328.333c-2.621,4.08-5.828,5.278-7.309,5.43c-5.874,0.602-9.802-3.821-10.354-9.217
		c-0.338-3.295,0.557-6.331,2.295-8.681c1.732-2.397,4.179-3.95,6.662-4.205c3.964-0.406,7.525,2.799,7.907,6.524
		c0.099,0.955-0.102,1.361-0.941,1.641c-0.798,0.227-6.718,1.316-12.381,2.089c0.714,6.489,4.713,8.782,8.056,8.438
		c1.909-0.195,3.646-1.145,5.199-2.945L366.031,328.333z M351.948,319.933c2.674-0.274,5.296-0.592,8.056-0.972
		c0.859-0.089,1.117-0.403,1.048-1.072c-0.207-2.488-2.064-4.613-4.691-4.345C354.164,313.77,352.268,315.99,351.948,319.933z"
      />
      <path
        d="M382.92,330.703l-0.127-1.241c2.788-0.576,3.083-0.992,2.746-4.287l-0.817-7.975
		c-0.338-3.295-1.705-5.326-4.904-4.998c-1.91,0.195-3.65,1.58-4.955,2.969l1.15,11.222c0.332,3.247,0.639,3.409,3.485,3.407
		l0.127,1.241l-10.362,1.062l-0.127-1.241c3.213-0.667,3.427-0.931,3.104-4.082l-1.014-9.886c-0.313-3.057-0.614-3.169-3.243-3.383
		l-0.112-1.098c2.249-0.666,4.435-1.469,6.438-2.64l0.392,3.821c0.882-0.814,1.859-1.639,2.97-2.572
		c1.249-0.996,2.432-1.697,3.816-1.839c3.772-0.387,6.43,1.994,6.904,6.627l0.945,9.216c0.332,3.247,0.591,3.414,3.39,3.417
		l0.127,1.241L382.92,330.703z"
      />
    </g>
  </svg>
</div>
