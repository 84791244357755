<div class="icon-wrapper">
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 425.2 425.2"
  >
    <g>
      <path d="M244.855,89.108l14.197-2.402l-0.889-65.486L47.691,6.479l-27.24,27.239l21.604,38.512l191.691,17.36
				L244.855,89.108L244.855,89.108z M155.651,30.452c1.312,0.058,2.324,1.216,2.261,2.631c-0.058,1.262-1.17,2.376-2.533,2.314
				c-1.314-0.058-2.321-1.268-2.266-2.53C153.176,31.455,154.286,30.391,155.651,30.452z M147.166,30.073
				c1.312,0.059,2.273,1.215,2.208,2.628c-0.055,1.264-1.117,2.38-2.481,2.318c-1.312-0.059-2.371-1.269-2.314-2.531
				C144.643,31.074,145.803,30.012,147.166,30.073z M88.805,59.65l-26.112-1.166l-0.369-0.775
				c7.516-9.684,15.082-19.316,22.613-29.302l-8.788-0.393c-5.454-0.243-6.282,0.175-7.2,1.501
				c-0.647,0.881-1.261,2.119-1.914,4.316l-1.517-0.067c0.399-3.221,0.869-6.995,1.021-9.266l1.213,0.055
				c0.552,1.188,1.105,1.314,2.621,1.382l20.149,0.9l0.322,0.723c-7.413,9.638-14.77,19.127-22.196,29.067
				c1.658,0.276,4.732,0.516,8.217,0.671c6.314,0.283,8.248,0.015,9.73-1.437c1.06-1.067,1.938-2.646,2.958-5.08l1.502,0.37
				C90.572,52.948,89.453,57.605,88.805,59.65z M117.043,59.697c-2.338,0.25-4.944,0.841-7.505,1.486l-0.29-0.316l0.161-3.585
				c-1.199,0.807-2.402,1.663-3.854,2.459c-1.503,0.794-2.583,1.15-3.945,1.089c-3.384-0.151-6.17-2.35-5.927-7.804l0.442-9.9
				c0.124-2.777-0.069-2.987-2.923-3.673l0.051-1.161c2.381-0.045,4.82-0.24,7.265-0.586c-0.224,1.558-0.33,3.932-0.484,7.367
				l-0.301,6.767c-0.201,4.495,1.918,5.805,4.191,5.905c1.817,0.082,3.772-0.589,5.562-2.18l0.526-11.769
				c0.128-2.877-0.164-3.093-3.779-3.761l0.051-1.162c2.635-0.034,5.269-0.119,7.97-0.554L113.5,55.188
				c-0.113,2.524,0.275,2.895,2.189,3.083l1.411,0.164L117.043,59.697z M135.104,44.006c-0.615,0.226-1.068,0.154-1.36-0.162
				c-0.672-0.79-1.264-1.12-2.073-1.156c-1.311-0.058-2.996,1.384-4.232,4.164l-0.414,9.242c-0.148,3.334,0.091,3.647,3.966,4.125
				l-0.059,1.313l-11.618-0.52l0.06-1.313c3.146-0.163,3.513-0.451,3.662-3.784l0.466-10.454c0.148-3.333-0.094-3.547-2.956-4.079
				l0.049-1.11c2.492-0.294,4.742-0.801,7.215-1.754l-0.246,5.506l0.101,0.004c1.627-2.457,3.924-5.189,6.349-5.08
				c1.716,0.076,2.871,1.443,2.817,2.656C136.786,42.615,136.084,43.595,135.104,44.006z M163.107,61.755
				c-2.339,0.25-4.946,0.841-7.502,1.487l-0.292-0.318l0.16-3.587c-1.202,0.808-2.403,1.665-3.856,2.46
				c-1.5,0.794-2.579,1.151-3.943,1.089c-3.383-0.151-6.17-2.351-5.926-7.804l0.443-9.898c0.124-2.779-0.07-2.99-2.923-3.675
				l0.052-1.161c2.379-0.045,4.817-0.24,7.262-0.586c-0.221,1.558-0.327,3.932-0.48,7.367l-0.303,6.767
				c-0.2,4.495,1.916,5.804,4.191,5.905c1.817,0.082,3.77-0.59,5.561-2.18l0.525-11.768c0.13-2.88-0.166-3.094-3.778-3.763
				l0.05-1.161c2.635-0.033,5.269-0.119,7.971-0.554l-0.754,16.868c-0.111,2.527,0.275,2.896,2.191,3.084l1.41,0.164L163.107,61.755
				z M175.707,64.14c-6.365-0.285-9.871-5.604-9.63-11.007c0.175-3.888,1.992-7.198,5.494-9.673
				c2.714-1.903,5.781-2.576,7.751-2.488c2.021,0.09,3.657,0.822,4.435,1.514c0.633,0.585,0.764,1.047,0.739,1.601
				c-0.047,1.061-1.21,2.123-1.764,2.098c-0.252-0.011-0.499-0.174-0.935-0.598c-1.45-1.481-3.19-2.218-4.957-2.295
				c-3.333-0.149-6.388,2.497-6.624,7.8c-0.314,7.02,4.381,9.557,7.208,9.684c2.173,0.096,3.868-0.436,6.286-2.503l0.813,1.098
				C182.169,62.201,178.685,64.272,175.707,64.14z M210.685,65.095l-6.925-0.21c-2.209-2.679-5.21-6.861-7.835-10.368
				c-0.619-0.889-1.061-1.161-1.516-1.182c-0.202-0.009-0.458,0.031-0.815,0.115l-0.249,5.606c-0.15,3.334,0.289,3.657,3.509,4.104
				l-0.055,1.313l-11.314-0.506l0.058-1.312c3.396-0.153,3.718-0.543,3.863-3.775l1.066-23.889c0.149-3.333-0.092-3.596-3.16-4.037
				l0.051-1.162c2.437-0.144,5.354-0.874,7.352-1.442l-1.062,23.786c1.527-0.235,2.466-0.851,3.26-1.624
				c1.161-1.062,2.763-2.963,3.784-4.285c1.08-1.521,0.946-1.932-1.417-2.289l0.06-1.314l10.324,0.006l-0.056,1.263
				c-3.513,0.45-4.595,0.908-6.65,2.688c-1.319,1.206-2.272,2.176-4.559,4.604c0.944,1.561,5.777,7.748,7.417,9.592
				c1.731,2,3.011,2.817,4.926,3.004L210.685,65.095z M238.484,72.732c-2.489,0-4.505-2.095-4.505-4.681s2.016-4.681,4.505-4.681
				c2.486,0,4.506,2.095,4.506,4.681S240.971,72.732,238.484,72.732z M238.484,52.017c-2.489,0-4.505-2.096-4.505-4.681
				c0-2.586,2.016-4.682,4.505-4.682c2.486,0,4.506,2.096,4.506,4.682C242.99,49.92,240.971,52.017,238.484,52.017z"/>
      <polygon points="244.855,89.108 239.121,90.078 233.747,89.591 233.403,89.606 232.879,91.7 244.855,89.847
				"/>
      <polygon points="245.902,155.376 231.475,156.811 231.397,159.749 245.883,158.874 245.902,158.873 			"/>
      <polygon points="229.023,224.274 224.066,417.257 245.902,418.718 244.92,223.648 229.873,224.241 			"/>
      <path d="M231.397,159.749l-121.711,7.352l-15.968,30.996l13.15,30.999l122.156-4.821l0.005-0.195l15.892-0.431
				l4.723-0.188v-64.813l-3.76,0.227l-14.492,1.132L231.397,159.749z M236.962,178.066c2.487,0,4.506,2.096,4.506,4.682
				c0,2.585-2.019,4.682-4.506,4.682c-2.488,0-4.505-2.097-4.505-4.682C232.457,180.162,234.474,178.066,236.962,178.066z
				 M236.962,198.781c2.487,0,4.506,2.096,4.506,4.682c0,2.586-2.019,4.683-4.506,4.683c-2.488,0-4.505-2.096-4.505-4.683
				C232.457,200.877,234.474,198.781,236.962,198.781z"/>
      <path d="M245.902,155.375L245.902,155.375l137.119-13.635l19.726-38.512l-19.726-34.754L244.855,89.847v0.231
				L232.879,91.7l0,0l-19.871,3.074v3.758v60.115l18.467-1.836l0,0L245.902,155.375z M238.484,112.175
				c2.486,0,4.506,2.096,4.506,4.681s-2.02,4.681-4.506,4.681c-2.489,0-4.505-2.095-4.505-4.681S235.995,112.175,238.484,112.175z
				 M238.484,132.89c2.486,0,4.506,2.096,4.506,4.681c0,2.586-2.02,4.682-4.506,4.682c-2.489,0-4.505-2.096-4.505-4.682
				C233.979,134.986,235.995,132.89,238.484,132.89z"/>
    </g>
  </svg>
</div>
