<div class="icon-wrapper">
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 425.2 425.2"
  >
    <g class="curtain">
      <path
        d="M325.019,72.189C363.154,71.127,394,42.001,394,42.001v-22H31v22c0,0,18.834,16.209,45.794,23.763
				c0.021-0.94,0.036-0.872,0.045,0.013c0.83,0.232,1.667,0.456,2.512,0.671c11.295,2.463,24.438,3.439,38.982,1.187
				c1.238-0.191,2.35-0.211,3.347-0.073c14.858-3.299,30.309-11.019,45.319-25.56c45,50,95,0,95,0
				c12.608,14.29,25.746,22.557,38.635,26.728c0.018-0.247,0.029-0.487,0.047-0.734C308.982,70.996,317.174,72.206,325.019,72.189z
				 M91.333,64.667c-14.041,0.521-33.39-9.782-45.069-15.616c-5.756-2.216-7.78-3.668-4.931-2.38c1.414,0.639,3.08,1.456,4.931,2.38
				c15.121,5.823,56.047,16.937,102.402-2.38C148.667,46.671,107.6,64.064,91.333,64.667z M232.096,42.888
				c0.436-0.317,0.855-0.62,1.237-0.887C234.578,41.133,234.047,41.777,232.096,42.888c-4.782,3.477-12.738,9.542-22.757,8.793
				C198.407,50.865,192,42.001,192,42.001C211.41,52.191,226.875,45.859,232.096,42.888z M178,46.112c0,0,44.667,17.89,72-4.111
				S214.666,83.557,178,46.112z M372.666,47.721c48-16.965-34,17.612-48,16.965s-47.333-16.965-47.333-16.965
				S324.666,64.686,372.666,47.721z"
      />
      <path
        d="M79.352,66.447c-0.847-0.185-1.686-0.375-2.511-0.576c0.078,8.005-0.209,73.75-0.209,76.13
				c0,17-22.6,93-22.6,93s21.972-86.299,22.6-155c0.074-8.061,0.125-12.477,0.161-14.143c-24.989-6.084-40.236-19.188-40.236-19.188
				S19.999,394.243,25.999,398.243s9.5-11.5,17-7s1.338,13.227,6,13s6.994-6.992,6.994-6.992l2.006-2.008c0,0,4.5,6.5,11-1
				s2.5,10,7,10s-1-12.5,6-12s-0.5,9.5,5,12s4-3.5,6-9s-1.81-147.242-24.81-160.242c0,0,84.885-163.074,53.491-167.44
				C106.6,70.91,92.133,69.699,79.352,66.447z M45.016,239.001L64,72.223C80,94.445,45.016,239.001,45.016,239.001z M40.052,155.001
				C40.536,144.974,48,64.686,48,64.686s5.104,66.613,6,78.315s-18,96-18,96S39.568,165.028,40.052,155.001z M40.052,312.001
				c-1.448,9-9.052,69-9.052,69l5-114C36,267.001,41.5,303.001,40.052,312.001z M31,238.809c-2.193-0.762,25.391,6.149,32.477,0
				c9.28-8.054-9.945,13.58-18.461,10.796S31,238.809,31,238.809z M50,388.501c0,0-7.5-119-4.984-118s6.129,27.192,9.015,40
				S50,388.501,50,388.501z M62.902,320.761c-1.346-7.157-1.329,23.258-1.148,45.657C62.071,379.9,62,388.501,62,388.501
				s-0.147-9.844-0.246-22.083c-0.556-23.622-2.304-62.241-7.724-95.923C49.223,240.611,61.525,312.761,62.902,320.761
				c0.02,0.104,0.039,0.199,0.059,0.319C63.07,321.728,63.04,321.561,62.902,320.761z M76.631,385.501
				c-0.44,5.02-5.631-78.225-13.604-106.5s13.473,43,13.604,59.5S77.071,380.481,76.631,385.501z M84,324.001c2,14.5,3.5,67,3.5,67
				s-3.28-69.562-19.311-112.59S82,309.501,84,324.001z M65.47,230.001c0,0,24.167-93.723,23.53-145c-0.333-26.854,10,26,5.359,51
				S65.47,230.001,65.47,230.001z M110.002,136c-2,14-36.667,88.178-36.667,88.178s30-86.845,32-142.178
				C105.335,82,112.002,122,110.002,136z"
      />
      <path
        d="M76.839,65.776c-0.015-0.004-0.03-0.009-0.045-0.013c-0.001,0.034-0.001,0.058-0.002,0.095
				c0.017,0.004,0.033,0.008,0.049,0.013C76.84,65.835,76.84,65.81,76.839,65.776z"
      />
      <path
        d="M349.484,310.359c-0.277-2.481-2.195-4.553-4.812-4.209c-2.189,0.287-4.021,2.561-4.229,6.511
				c2.664-0.351,5.275-0.742,8.024-1.2C349.324,311.349,349.572,311.026,349.484,310.359z"
      />
      <path
        d="M389.713,378.278l-0.175-1.333c2.947-0.774,3.325-1.212,2.464-2.986c-0.863-1.775-2.151-4.221-3.58-6.986
				l-10.139,1.331c-0.479,2.242-0.931,4.335-1.287,6.415c-0.653,3.136-0.162,3.557,3.45,3.421l0.175,1.332l-8.647,1.136
				c-0.528,8.776-0.882,14.394-0.882,14.394s-5.432-51.195-4.659-71.762l-8.146,1.07l-0.163-1.237
				c2.914-0.673,3.21-1.003,2.797-4.144l-1.293-9.854c-0.412-3.141-0.676-3.299-3.439-3.324l-0.137-1.047
				c2.282-0.687,4.302-1.533,6.455-2.832l0.682,5.188l0.094-0.012c1.116-2.568,2.812-5.502,5.098-5.803
				c0.623-0.082,1.197,0.032,1.68,0.265c2.132-11.74,4.323-23.835,6.018-24.509c1.934-0.769-2.048,69.318-3.995,101.819
				c1.359-0.663,1.917-1.737,2.725-4.927l6.57-26.282l1.42-0.622c4.025,7.799,8.402,16.423,12.536,24.306
				c0.479,0.904,0.881,1.61,1.259,2.171c-1.035-87.184-8.726-325.745-8.726-325.745s-27.982,24.393-62.847,24.468
				c-7.886,0.219-16.083-0.773-24.384-3.46c-8.691,124.441,55.866,170.272,55.866,170.272
				c-15.464,19.447-20.128,53.824-21.542,85.692c0.252-0.143,0.483-0.285,0.669-0.41l0.591,1.181l-1.329,0.922
				c-0.936,22.838-0.263,44.218-0.781,57.667c0.489,0.005,1.062-0.02,1.747-0.059l0.176,1.333l-1.988,0.261
				c-0.316,6.591-0.977,11.022-2.361,12.407c-8,8,9.48,4.529,9.48,4.529s-2.48-8.529,5.52-4.529s4.319,8.332,9.819,6.832
				s0.951-9.326,6.951-9.826s4.739,10.326,8.739,9.826s4.49-2.832,9.49-5.832s11.519,11.332,14.519,5.832
				c0.491-0.9,0.598-11.074,0.434-27.458L389.713,378.278z M388.978,243.666c4.15-3.602,2.927-2.127-0.447,0.352
				C388.68,243.9,388.833,243.792,388.978,243.666z M379.742,326.001c-1.447-9,4.053-45,4.053-45l0.353,65.988
				C384.147,346.989,381.19,335.001,379.742,326.001z M387.712,244.606c-4.273,3.024-11.107,6.978-15.521,5.534
				c-8.516-2.784-10.627-8.282-10.627-8.282S378.148,250.868,387.712,244.606z M369.923,75.485
				c2,55.333,9.577,154.516,9.577,154.516s-12.243-86.516-14.243-100.516S369.923,75.485,369.923,75.485z M348.875,72.223
				c0.628,68.701,22.6,155,22.6,155s-22.6-76-22.6-93V72.223z M309.209,134.754c-2-14,4.666-54,4.666-54
				c2,55.333,32,142.178,32,142.178S311.209,148.754,309.209,134.754z M324.555,134.051c-4.641-25,5.693-77.854,5.359-51
				c-0.637,51.277,23.529,145,23.529,145S329.195,159.051,324.555,134.051z M342.283,159.49c0-17-0.628-130.701,0-62
				s22.6,139.511,22.6,139.511S342.283,176.49,342.283,159.49z M339.042,308.632c1.664-2.445,4.065-4.068,6.54-4.393
				c3.951-0.52,7.602,2.584,8.09,6.296c0.125,0.952-0.063,1.364-0.895,1.668c-0.791,0.249-6.678,1.505-12.316,2.439
				c0.897,6.467,4.96,8.645,8.291,8.207c1.904-0.25,3.613-1.248,5.113-3.091l0.894,0.898c-2.505,4.154-5.677,5.442-7.152,5.636
				c-5.854,0.769-9.905-3.541-10.612-8.919C336.563,314.09,337.372,311.03,339.042,308.632z M362.252,381.884
				c-4.209-3.66-8.676-7.817-13.622-12.301c-1.263-1.141-1.955-1.244-2.745-0.995l1.188,9.044c0.55,4.188,1.069,4.459,5.01,4.232
				l0.176,1.332l-12.376,1.625l-0.175-1.332c3.58-0.761,3.965-1.15,3.414-5.339l-2.443-18.609c-0.568-4.332-1.028-4.514-4.826-4.306
				l-0.175-1.332l12.28-1.612l0.175,1.332c-3.628,0.768-4.06,1.163-3.503,5.398l1.094,8.329c1.01-0.423,1.873-1.214,3.044-2.627
				c2.286-2.865,5.091-6.575,6.89-9.475c1.154-1.895,1.093-2.37-1.128-2.32l-1.065-0.006l-0.176-1.332l11.424-1.5l0.174,1.333
				c-3.573,0.808-4.605,1.428-7.289,4.587c-1.59,1.904-4.525,5.728-7.33,9.437c4.205,3.999,10.168,9.22,12.885,11.477
				c2.879,2.382,3.887,2.686,6.256,2.665l0.176,1.333L362.252,381.884z"
      />
      <path
        d="M366.807,305.131c-1.238,0.163-2.573,1.792-3.27,4.594l1.144,8.71c0.285,2.168,0.505,2.96,1.83,3.18
				c0.113-1.898,0.292-3.469,0.55-4.613c0.692-3.07,1.426-6.913,2.177-10.991c-0.108-0.028-0.209-0.072-0.3-0.142
				C368.177,305.242,367.568,305.03,366.807,305.131z"
      />
      <path
        d="M381.609,353.438c-0.986,4.294-1.965,8.635-2.881,12.725l8.758-1.149l-5.782-11.587L381.609,353.438z"
      />
      <path
        d="M108.546,355.748c-4.369,0.281-4.787,0.047-5.324-4.047l-1.131-8.614l4.902-0.644
				c4.331-0.569,4.597-0.022,5.641,3.133l1.333-0.175l-1.2-9.139l-1.332,0.175c-0.219,3.128-0.354,3.581-4.685,4.149l-4.902,0.644
				l-1.488-11.327c-0.181-1.381-0.051-1.494,1.33-1.676l3.617-0.475c3.617-0.476,4.714-0.232,5.651,1
				c0.798,0.913,1.202,1.779,1.833,3.633l1.403-0.378c-0.532-2.205-1.17-5.221-1.395-6.934l-20.942,2.75l0.175,1.333
				c3.703-0.196,4.115-0.008,4.658,4.133l2.487,18.942c0.538,4.094,0.194,4.429-3.575,5.215l0.175,1.333l12.945-1.7L108.546,355.748
				z"
      />
      <path
        d="M118.479,337.014c2.918,0.101,3.086,0.272,3.474,3.223l1.288,9.806c0.425,3.236,0.129,3.565-2.832,4.245
				l0.163,1.237l10.471-1.375l-0.163-1.237c-3.083,0.114-3.454-0.128-3.879-3.364l-2.156-16.421
				c-1.982,1.132-4.138,2.044-6.515,2.744L118.479,337.014z"
      />
      <path
        d="M122.123,329.369c1.38-0.182,2.277-1.461,2.109-2.746c-0.188-1.428-1.372-2.338-2.705-2.163
				c-1.285,0.169-2.29,1.366-2.103,2.795C119.593,328.539,120.885,329.531,122.123,329.369z"
      />
      <path
        d="M151.389,330.658l-0.335-0.343c-1.676,0.51-4.066,1.115-6.385,1.517c-1.48-0.581-3.107-0.803-4.726-0.591
				c-3.665,0.481-8.195,3.594-7.482,9.021c0.425,3.236,2.765,5.204,5.025,5.827c-0.636,1.052-1.997,2.489-3.807,3.453
				c0.015,0.482,0.265,1.273,0.786,1.931c0.75,0.918,2.083,1.856,3.866,2.154c-1.03,1.007-2.686,2.774-3.512,3.852
				c-0.621,0.807-0.931,1.768-0.793,2.814c0.404,2.707,3.713,5.42,8.997,4.726c6.331-0.831,11.292-6.179,10.661-10.986
				c-0.612-4.664-4.64-5.104-7.591-4.716c-1.571,0.206-3.278,0.479-4.992,0.704c-2.142,0.281-3.117-0.511-3.242-1.463
				c-0.087-0.666,0.562-1.623,1.171-2.14c0.774-0.004,1.649,0.026,2.458-0.08c4.046-0.531,8.004-3.568,7.33-8.709
				c-0.225-1.713-0.942-3.12-1.984-4.049l2.732-0.213C150.271,332.839,151.061,331.477,151.389,330.658z M139.337,354.657
				c0.757-0.873,1.767-1.298,4.051-1.598c1.999-0.263,3.398-0.301,4.65,0.02c1.455,0.391,2.42,1.473,2.633,3.092
				c0.388,2.951-1.638,5.589-6.16,6.183c-3.522,0.463-6.539-1.126-6.876-3.696C137.455,357.277,138.231,355.819,139.337,354.657z
				 M141.765,344.752c-2.665,0.351-4.824-2.078-5.311-5.79c-0.488-3.713,1.106-5.955,3.201-6.229
				c2.713-0.356,4.823,2.077,5.317,5.838C145.465,342.33,143.853,344.43,141.765,344.752z"
      />
      <path
        d="M165.987,347.091c-2.142,0.281-4.338-0.591-4.895-4.828l-0.837-6.377
				c-0.425-3.237-0.719-5.475-0.77-6.969c-2.229,0.729-4.476,1.314-6.694,1.751l0.144,1.095c2.782,0.167,2.998,0.333,3.341,2.95
				l1.225,9.329c0.675,5.14,3.645,6.735,6.833,6.316c1.285-0.169,2.234-0.682,3.508-1.671c1.227-0.983,2.208-1.985,3.197-2.938
				l0.443,3.379l0.323,0.248c2.286-1.026,4.625-2.012,6.771-2.632l-0.156-1.189l-1.346,0.079c-1.821,0.142-2.246-0.142-2.558-2.521
				l-2.088-15.897c-2.454,0.855-4.904,1.371-7.36,1.839l0.144,1.095c3.489,0.026,3.799,0.179,4.156,2.892l1.457,11.09
				C169.415,345.915,167.701,346.866,165.987,347.091z"
      />
      <path
        d="M191.7,344.926c-3.703,0.195-3.978-0.06-4.39-3.2l-1.144-8.711c0.697-2.802,2.032-4.431,3.27-4.594
				c0.761-0.1,1.37,0.112,2.13,0.738c0.323,0.248,0.757,0.239,1.297-0.073c0.848-0.548,1.342-1.58,1.217-2.533
				c-0.15-1.142-1.455-2.229-3.073-2.017c-2.284,0.3-3.981,3.234-5.097,5.802l-0.095,0.013l-0.681-5.188
				c-2.153,1.3-4.173,2.146-6.455,2.833l0.137,1.047c2.763,0.024,3.026,0.184,3.439,3.324l1.293,9.853
				c0.413,3.142,0.117,3.471-2.797,4.144l0.163,1.238l10.947-1.438L191.7,344.926z"
      />
      <path
        d="M213.565,328.933c-0.487-3.712-4.139-6.815-8.089-6.296c-2.475,0.324-4.876,1.947-6.541,4.393
				c-1.67,2.398-2.479,5.458-2.047,8.742c0.706,5.378,4.758,9.688,10.612,8.919c1.476-0.193,4.647-1.481,7.152-5.636l-0.894-0.898
				c-1.5,1.843-3.209,2.841-5.113,3.091c-3.332,0.438-7.394-1.74-8.292-8.207c5.639-0.935,11.525-2.19,12.316-2.439
				C213.502,330.297,213.69,329.886,213.565,328.933z M208.361,329.858c-2.749,0.457-5.36,0.85-8.025,1.199
				c0.207-3.949,2.039-6.223,4.229-6.51c2.618-0.344,4.536,1.728,4.813,4.209C209.465,329.424,209.217,329.746,208.361,329.858z"
      />
      <path
        d="M231.438,339.707l0.163,1.237l9.899-1.3l-0.163-1.237c-2.797,0.077-3.061-0.083-3.484-3.318l-1.207-9.187
				c-0.605-4.617-3.33-6.922-7.09-6.428c-1.381,0.181-2.543,0.914-3.763,1.946c-1.084,0.965-2.037,1.816-2.896,2.655l-0.5-3.808
				c-1.969,1.227-4.131,2.092-6.359,2.82l0.144,1.096c2.633,0.138,2.937,0.242,3.337,3.289l1.294,9.853
				c0.413,3.141,0.206,3.41-2.987,4.168l0.162,1.238l10.329-1.356l-0.163-1.237c-2.846,0.083-3.156-0.07-3.58-3.307l-1.469-11.185
				c1.265-1.425,2.965-2.859,4.869-3.109c3.188-0.418,4.611,1.573,5.043,4.857l1.044,7.948
				C234.492,338.629,234.209,339.053,231.438,339.707z"
      />
      <path
        d="M250.76,339.01c0.429-0.056,1.165-0.347,1.918-0.881l3.018-2.092l-0.592-1.181
				c-0.621,0.42-1.654,1.04-2.844,1.196s-2.854-0.352-3.36-4.206l-1.606-12.232l5.093-0.668c0.527-0.408,0.532-1.475,0.042-1.895
				l-5.379,0.706l-0.855-6.521l-0.68-0.008l-2.681,3.549l0.456,3.474l-2.189,0.287l-1.104,1.549l0.253,0.451l3.284-0.431
				l1.818,13.85C245.846,337.719,247.762,339.403,250.76,339.01z"
      />
      <path
        d="M266.938,317.325c3.189-0.418,4.922,1.339,5.434,5.242l1.037,7.9c0.406,3.094,0.063,3.43-2.66,4.077
				l0.163,1.238l10.042-1.319l-0.162-1.237c-2.839,0.131-3.209-0.111-3.622-3.252l-1.094-8.33c-0.75-5.711-3.782-7.782-7.637-7.276
				c-2.57,0.338-4.909,2.438-6.351,4.368l-2.1-15.991c-1.781,0.814-4.537,1.951-6.777,2.584l0.144,1.096
				c3.179-0.127,3.306,0.098,3.698,3.096l2.981,22.704c0.413,3.141,0.021,3.482-2.802,4.096l0.162,1.237l10.09-1.325l-0.162-1.237
				c-2.846,0.083-3.127-0.219-3.527-3.265l-1.488-11.327C263.439,318.704,265.129,317.563,266.938,317.325z"
      />
      <path
        d="M298.942,317.722c-0.487-3.712-4.139-6.814-8.089-6.297c-2.476,0.326-4.877,1.948-6.541,4.394
				c-1.67,2.399-2.479,5.458-2.047,8.742c0.706,5.378,4.758,9.688,10.611,8.919c1.477-0.193,4.648-1.481,7.152-5.636l-0.893-0.898
				c-1.501,1.843-3.21,2.841-5.113,3.091c-3.332,0.438-7.395-1.74-8.292-8.207c5.639-0.935,11.525-2.19,12.316-2.439
				C298.879,319.086,299.067,318.675,298.942,317.722z M293.738,318.647c-2.748,0.457-5.359,0.85-8.025,1.199
				c0.207-3.949,2.039-6.223,4.229-6.51c2.618-0.344,4.535,1.728,4.813,4.209C294.843,318.213,294.595,318.535,293.738,318.647z"
      />
      <path
        d="M303.44,326.186c0.394,2.999,3.095,5.501,6.474,5.057c1.428-0.188,3.481-2.248,5.217-3.783
				c0.344,0.778,0.717,1.406,1.424,1.991c0.586,0.408,1.642,0.705,2.355,0.611l3.727-2.959l-0.639-1.175
				c-0.438,0.348-1.072,0.674-1.645,0.749c-0.809,0.105-1.76-0.496-2.041-2.638l-1.162-8.853c-0.324-2.475-1.057-4.363-2.904-5.525
				c-1.16-0.72-2.644-0.961-4.072-0.773c-2.644,0.881-5.494,3.143-6.701,4.27c-0.816,0.785-1.367,1.389-1.273,2.104
				c0.145,1.095,1.507,1.885,2.363,1.772c0.666-0.088,1.146-0.489,1.236-1.276c0.188-1.525,0.479-2.629,1.016-3.328
				c0.401-0.635,1.064-1.108,2.018-1.233c2.379-0.312,4.254,1.426,4.703,4.853l0.113,0.857c-0.936,0.994-4.24,2.735-6.971,4.062
				C304.178,322.215,303.135,323.854,303.44,326.186z M309.84,321.085c0.937-0.607,3.09-1.907,4.021-2.562l0.976,7.426
				c-0.792,0.976-2.352,2.004-3.583,2.213c-1.476,0.193-3.535-0.745-3.86-3.221C307.168,323.228,308.096,322.186,309.84,321.085z"
      />
      <path
        d="M332.783,325.479c-1.189,0.156-2.854-0.352-3.36-4.206l-1.606-12.232l5.093-0.669
				c0.527-0.408,0.532-1.475,0.042-1.895l-5.379,0.707l-0.855-6.521l-0.68-0.008l-2.681,3.548l0.456,3.475l-2.189,0.287
				l-1.104,1.549l0.253,0.451l3.284-0.432l1.818,13.851c0.494,3.761,2.41,5.445,5.408,5.052c0.429-0.057,1.165-0.347,1.918-0.881
				l1.688-1.17c0.022-0.564,0.044-1.127,0.069-1.692C334.366,325.03,333.618,325.37,332.783,325.479z"
      />
      <path
        d="M370.234,305.796c0.848-0.548,1.342-1.581,1.217-2.533c-0.093-0.702-0.623-1.381-1.393-1.753
				c-0.275,1.516-0.55,3.022-0.821,4.5C369.514,306.082,369.846,306.021,370.234,305.796z"
      />
      <path
        d="M369.07,321.635c-1.147,0.061-1.965,0.077-2.56-0.021c-0.03,0.518-0.057,1.06-0.078,1.625l2.8-0.367
				L369.07,321.635z"
      />
      <path
        d="M119.736,384.292c-2.091-1.178-4.879-1.393-8.258-0.949l-11.756,1.544l0.175,1.332
				c3.554-0.224,3.959-0.084,4.509,4.104l2.5,19.038c0.519,3.95,0.188,4.382-3.583,5.167l0.175,1.333l12.899-1.694l-0.175-1.332
				c-4.321,0.276-4.781,0.095-5.312-3.95l-0.981-7.474l3.641,0.442c0.53-0.021,1.435-0.141,2.142-0.281
				c4.536-1.226,8.806-4.836,8-10.976C123.298,387.454,121.773,385.429,119.736,384.292z M113.271,399.956
				c-1.571,0.206-2.899,0.042-3.55-0.115l-1.531-11.661c-0.175-1.333-0.107-1.923,0.23-2.306c0.289-0.377,1.257-0.746,2.59-0.921
				c3.903-0.513,7.475,1.246,8.149,6.386C119.954,397.385,116.365,399.55,113.271,399.956z"
      />
      <path
        d="M149.953,406.469c-0.885,1.374-2.167,1.931-5.785,2.406c-2.38,0.312-4.052,0.483-5.031,0.031
				c-0.938-0.507-1.354-1.47-1.648-3.706l-1.181-8.996l4.521-0.594c4.141-0.544,4.512-0.301,5.58,3.044l1.38-0.182l-1.2-9.138
				l-1.38,0.181c-0.2,3.271-0.388,3.684-4.671,4.246l-4.474,0.588l-1.418-10.806c-0.188-1.428-0.105-1.535,1.37-1.729l3.618-0.476
				c3.427-0.449,4.755-0.285,5.553,0.626c0.756,0.967,1.37,1.951,2.061,3.895l1.349-0.42c-0.461-2.408-1.125-5.614-1.281-6.803
				l-21.228,2.787l0.175,1.332c3.757-0.154,4.156-0.061,4.719,4.223l2.487,18.942c0.506,3.855,0.176,4.286-4.112,5.188l0.175,1.332
				l22.941-3.013c0.234-1.531,0.599-6.131,0.679-7.738l-1.453-0.003C151.328,403.674,150.636,405.41,149.953,406.469z"
      />
      <path
        d="M177.474,373.466c-0.643,1.004-0.986,1.341-2.271,1.51l-18.8,2.469c-1.333,0.175-1.964,0.16-2.774-0.848
				l-0.952,0.125c0.249,2.631,0.417,5.757,0.554,8.643l1.38-0.182c0.296-2.168,0.502-3.551,0.967-4.436
				c0.475-1.176,1.376-1.682,5.327-2.2l2.951-0.388l3.075,23.417c0.494,3.76,0.176,4.286-4.118,5.141l0.175,1.333l13.66-1.794
				l-0.175-1.332c-4.559,0.308-5.002-0.119-5.496-3.879l-3.075-23.417l3.379-0.443c3.57-0.469,4.423-0.242,5.334,0.801
				c0.671,0.686,1.255,1.819,2.079,4.035l1.361-0.324c-0.601-2.729-1.294-6.172-1.582-8.362L177.474,373.466z"
      />
      <path
        d="M206.049,394.213c-1.709-1.954-4.127-4.881-5.142-6.346c3.322-1.985,5.513-4.839,4.976-8.933
				c-0.375-2.855-1.894-4.835-3.959-5.822c-1.965-0.952-4.354-1.074-7.828-0.618l-11.804,1.551l0.175,1.332
				c3.757-0.154,4.156-0.062,4.687,3.984l2.513,19.133c0.531,4.046,0.14,4.388-3.725,5.186l0.175,1.333l12.518-1.644l-0.175-1.333
				c-3.845,0.215-4.311-0.015-4.842-4.062l-0.994-7.567l1.523-0.2c2.475-0.325,3.359,0.141,4.343,1.367
				c1.734,2.146,3.835,4.87,5.807,6.984c2.548,2.812,4.685,3.597,8.279,3.318c0.578-0.027,1.101-0.097,1.671-0.172l0.028-1.262
				c-1.084-0.148-2.248-0.527-3.72-1.787C209.249,397.569,208.087,396.463,206.049,394.213z M194.7,388.537l-2.284,0.3
				l-1.538-11.708c-0.168-1.285-0.053-1.882,0.249-2.163c0.343-0.336,1.127-0.632,2.508-0.813c3.617-0.475,7.247,0.985,7.921,6.125
				c0.337,2.57-0.174,4.575-1.843,6.246C198.648,387.631,197.128,388.218,194.7,388.537z"
      />
      <path
        d="M242.34,367.515l-0.176-1.332l-11.328,1.487l0.176,1.332c3.362-0.199,4.438,0.241,4.967,2.059
				c0.384,1.451,0.789,3.432,1.364,7.811l0.475,3.617c1.031,7.854-0.36,13.846-7.214,14.746c-5.902,0.775-9.523-3.204-10.529-10.867
				l-1.426-10.853c-0.562-4.283-0.219-4.618,3.51-5.35l-0.175-1.332l-12.327,1.618l0.175,1.332c3.804-0.16,4.21-0.021,4.772,4.263
				l1.562,11.9c0.731,5.568,2.532,8.963,5.676,10.777c2.307,1.343,5.154,1.646,7.915,1.284c3.142-0.413,6.199-1.831,8.089-4.402
				c2.379-3.266,2.705-7.424,1.967-13.04l-0.519-3.95c-0.575-4.38-0.749-6.438-0.774-7.742
				C238.501,368.89,239.508,368.08,242.34,367.515z"
      />
      <path
        d="M257.457,377.586c-4.016-1.459-6.514-2.776-6.97-6.252c-0.337-2.569,0.931-5.446,4.597-5.928
				c4.141-0.544,5.809,2.577,7.065,5.511l1.343-0.467c-0.774-2.948-1.256-4.773-1.672-6.463c-1.883-0.332-3.951-0.594-6.141-0.307
				c-6.14,0.807-9.292,5.19-8.691,9.76c0.705,5.378,5.824,7.127,9.084,8.346c3.819,1.435,6.656,2.756,7.119,6.278
				c0.455,3.475-1.393,5.993-4.963,6.462c-4.902,0.644-7.675-3.882-8.817-6.686l-1.337,0.514c0.586,2.248,1.742,6.26,2.254,7.21
				c0.459,0.182,1.217,0.421,2.464,0.692c1.233,0.177,2.807,0.357,4.758,0.102c6.33-0.831,10.042-5.386,9.386-10.383
				C266.234,380.645,261.42,379.002,257.457,377.586z"
      />
      <path
        d="M289.021,390.483c-7.234,0.95-13.27-4.455-14.438-13.356c-0.95-7.233,2.404-14.501,10.354-15.544
				c5.14-0.675,8.265,0.997,10.164,5.879l1.385-0.521c-0.623-2.532-1.159-4.399-1.896-7.062c-1.364-0.063-4.738-0.686-8.594-0.18
				c-10.613,1.394-17.455,9.022-16.143,19.018c0.993,7.566,7.256,15.072,19.107,13.516c3.427-0.45,7.3-1.927,8.589-2.435
				c0.65-1.683,1.378-5.361,1.757-7.639l-1.449-0.343C296.103,386.891,293.876,389.846,289.021,390.483z"
      />
      <path
        d="M331.025,380.029l-2.524-19.229c-0.519-3.95-0.169-4.238,3.69-5.084l-0.176-1.333l-12.422,1.632
				l0.175,1.332c3.757-0.154,4.168,0.034,4.687,3.984l1.013,7.71l-16.229,2.131l-1.013-7.71c-0.519-3.95-0.169-4.238,3.404-5.047
				l-0.175-1.332l-12.328,1.619l0.176,1.332c3.946-0.18,4.352-0.04,4.877,3.959l2.525,19.229c0.524,3.998,0.176,4.286-3.596,5.072
				l0.176,1.332l12.518-1.644l-0.175-1.333c-3.94,0.228-4.358-0.009-4.878-3.959l-1.25-9.519l16.23-2.131l1.25,9.519
				c0.525,3.998,0.127,4.292-3.881,5.109l0.176,1.332l10.768-1.414c0.023-0.499,0.046-1.011,0.065-1.535
				C331.819,384.029,331.458,383.323,331.025,380.029z"
      />
      <path
        d="M401.373,376.747l-0.175-1.332c-2.403,0.092-3.361-0.098-4.607-1.949c0.016,1.335,0.03,2.642,0.043,3.903
				L401.373,376.747z"
      />
      <path
        d="M370.119,379.496l0.174,1.332l1.681-0.221c0.035-0.581,0.071-1.179,0.107-1.787
				C371.562,379.073,370.932,379.269,370.119,379.496z"
      />
    </g>
  </svg>
</div>
