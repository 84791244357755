<div class="wrapper">
  <svg
    id="svg-header-image"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 35 537 200"
    xml:space="preserve"
  >
    <g class="curtain">
      <path
        d="M303.825,193.597c0,0-4.55-32.958-4.013-35.474c0.536-2.52-25.928-2.299-27.758-0.925
	c-1.729,1.3-22.522,23.73-24.517,28.218c5.566-6.755,0.299,2.538,2.885,9.664c2.156,5.935-8.159,13.654-7.978,16.904
	C246.46,215.059,255.12,167.963,303.825,193.597"
      />
      <path
        d="M211.78,63.498c-0.323-3.552,0.581,16.937,1.291,18.189c-0.331-0.507-3.551-11.731-3.551-11.731
	s-3.982,6.888-6.35,9.58c-0.133,0.149,3.58,0.288,4.308,0.946c0.476,0.427,5.361,2.898,6.156,3.583
	c-0.039-0.015-0.065-0.024-0.065-0.024s0.311,0.224,0.427,0.296c1.151,0.727,12.757,6.304,13.315,6.806
	c0.516,0.467,1.499,2.446,0.757,1.701c-1.8-1.81-8.402-4.651-8.402-4.651s2.87,2.018,6.063,4.407
	c-1.933,0.205-9.878,6.869-10.178,8.561c-0.682,3.849,4.585-3.314,4.585-3.314s4.678-4.621,5.797-4.397
	c1.468,0.297,4.226,3.73,5.907,5.567c1.851,4.734,6.351,15.852,6.498,20.05c0.152,4.268,0.083,15.531-0.891,18.328
	c-0.058,0.094-0.119,0.203-0.191,0.334c0.155-0.262,0.296-0.5,0.421-0.717c-0.51,1.575-1.402,3.174-2.431,4.616
	c0.866-1.563,1.579-3.081,2.01-3.899c-0.898,1.541-2.242,3.799-3.482,5.771c-1.27,1.462-2.563,2.604-3.493,3.144
	c-1.803,1.051-8.139,2.269-11.495,2.69c-3.829-0.921-15.537-6.677-22.343-10.119c-1.457-4.37-4.715-13.651-6.68-14.16
	c-1.643-0.432-4.635-5.93-5.751-12.8c0.995-6.908,0.827-12.258,1.937-14.207c2.346-4.139,12.011-2.906,18.082-15.499
	c0.799-3.085-13.228-16.635-13.228-16.635s4.611,17.1,5.155,17.927c-1.516,0.834-15.498,1.615-15.498,1.615l6.134,8.287
	c-0.427-1.753-14.799-9.685-14.799-9.685l17.813-4.738c0,0-1.025-15.278-0.968-16.358c-3.149-1.694-18.835,14.421-24.325,24.431
	c-5.502,10.033,1.553,27.198,1.83,28.737c11.445,63.596-48.649,30.137-17.223,24.54c1.739-0.31-42.19-1.724,4.306,21.956
	c26.858,13.677,30.82-23.186,25.402-14.854c-5.42,8.334-5.344,76.366-16.736,55.859c-2.422-4.357,5.65,1.292,3.39-2.421
	c-4.999-8.212-16.789,8.655,9.904,17.304c6.886,2.23,3.872-7.67,11.621-24.459c1.006-5.76,9.071-25.198,9.013-25.022
	c-0.207,0.647,1.834,1.453,5.191,2.303c-0.822,1.177-10.438,12.481-11.532,23.797c-1.094,11.316,2.584,14.944-1.619,9.945
	c-4.204-4.999,1.103,18.986-1.568,8.905c-2.67-10.083-0.001-17.288,0.697-18.89c0.698-1.605,9.025-23.235,8.822-23.234
	c-1.569-0.01-11.242,22.644-11.963,24.309c-0.722,1.663-1.409,16.823-0.72,19.622c0.694,2.796,12.824-29.563,14.487-26.714
	c1.66,2.849,48.909,0.201,52.194-4.38c2.766-3.857,30.698-26.948,29.642-26.18c-3.708-2.281-4.314,0.532-2.904-4.408
	c13.702-8.32-17.047-12.605-17.047-12.605c2.017,1.445-4.856,35.51-9.046,36.392c-0.188,0.04-0.357,0.081-0.526,0.121
	c-0.433,0.593-0.842,0.959-1.225,1.04c-0.102,0.02-0.189,0.043-0.286,0.062c-0.236,0.218-0.466,0.359-0.685,0.406
	c-4.52,0.954-4.35,1.686-6.903,1.378c-2.562-0.302-23.04-4.359-27.92-4.987c-0.329-0.042-0.691-0.1-1.068-0.167
	c0.715-0.547,1.323-1.195,1.824-1.896c13.778,2.306,30.721,4.224,32.078,3.615c2.341-1.04,11.295-30.085,10.973-34.398
	c-0.32-4.313-0.827-6.258-1.63-5.701c-0.805,0.556-7.755,3.054-11.556,2.222c-3.257-0.715-4.281-9.433-4.505-11.897
	c0.142-1.62,0.256-2.91,0.278-3.171c0.075-0.815-1.504-12.674-1.504-12.674s-0.166-0.583-0.438-1.499l0.06,0.036
	c0.545,2.041,1.238,3.986,2.164,5.624c4.059,7.188,0.944,17.376,4.045,18.943c3.102,1.566,12.649,2.281,13.9,1.721
	c1.248-0.557,16.163,4.984,17.949,5.32c11.854,2.23,12.291-9.139,13.146-10.685c0.425-0.764-6.458,10.978-15.498,4.52
	c-5.487-3.919-6.458-10.303-6.458-13.561c0-7.427,13.275-15.44,15.285-21.096c2.003-5.655-3.323-15.97-6.89-17.436
	c-3.562-1.466-14.371,3.544-13.992,7.32c0.753,7.533,9.04,5.381,9.04,5.381s-7.641-3.983-1.074-6.673
	c4.007-1.643,6.349,0.861,6.349,3.874c0,1.373-1.82,9.574-13.777,10.01c-11.961,0.437-3.507-7.994-8.502-14.638
	c-4.995-6.645-8.073-3.875-19.374-14.207c-6.605-6.039-15.894-17.795-28.091-16.144c-12.2,1.652-16.414,2.407-15.391,3.984
	c1.278,0.826,13.347,10.008,13.347,10.008 M243.267,142.65c3.562,0.74,10.088-1.537,10.848-2.039
	c0.757-0.504,1.224,1.254,1.514,5.144c0.29,3.896-8.188,30.146-10.39,31.089c-1.314,0.569-18.196-1.24-31.321-3.376
	c0.869-1.33,1.407-2.774,1.707-3.914c0.652-2.476-4.116-11.353-8.052-16.931c0.016-0.141,0.024-0.273,0.009-0.385
	c-0.275-2.305-1.626-5.447-3.579-6.928c3.386,1.741,12.951,6.369,14.417,6.344c1.93-0.029,10.327-1.439,12.4-2.598
	c2.069-1.162,6.065-5.373,7.13-9.257c1.064-3.881,1.071-8.469,1.071-8.469S239.704,141.907,243.267,142.65z M238.627,132.247
	c0.088-1.017,0.188-2.13,0.281-3.191C238.857,129.94,238.774,131.046,238.627,132.247z M192.905,137.543l-0.671-0.332l-0.078-0.135
	c-0.001-0.006-0.004-0.01-0.005-0.016c0.022,0.01,0.038,0.019,0.059,0.032l0.02,0.062L192.905,137.543z"
      />
      <path
        d="M332.264,133.486c2.943,1.598,5.87,1.83,6.807,0.621c-0.595,0.718-2.263,0.684-4.258,0.002
	c0.935-0.338,1.72-1.053,2.061-2.068c0.21-0.604,0.216-1.228,0.088-1.82c1.732,1.377,2.678,2.837,2.25,3.714
	c0.007-0.004,0.014-0.014,0.018-0.021c0.741-1.45-1.172-3.983-4.271-5.666c-3.104-1.685-6.223-1.874-6.969-0.426
	c0,0.007,0,0.015-0.007,0.019c0.464-0.843,2.15-0.866,4.205-0.194c-0.887,0.351-1.621,1.044-1.949,2.017
	c-0.212,0.628-0.214,1.274-0.067,1.881c-1.547-1.247-2.447-2.555-2.257-3.431C327.518,129.592,329.352,131.907,332.264,133.486"
      />
      <path
        d="M187.118,115.211c0,0,13.855-9.477,17.303-6.946c0,0,2.179,0.023,2.11-0.61
	C202.402,102.22,187.118,115.211,187.118,115.211"
      />
      <path
        d="M230,103.873c0,0-3.203-2.323-6.343-0.752c-0.056,0.026-0.108,0.056-0.163,0.085
	c-3.082,1.667-4.188,5.64-4.188,5.64s3.909,1.389,7.048-0.172c0.067-0.031,0.131-0.066,0.194-0.099
	C229.617,106.929,230,103.873,230,103.873 M225.563,103.524c0.059-0.003,0.115,0.006,0.168,0.026
	c0.031,0.011,0.058,0.032,0.086,0.048c0.016,0.011,0.034,0.019,0.048,0.032c0.038,0.031,0.07,0.067,0.097,0.109
	c0.001,0.003,0.003,0.005,0.005,0.007c0.03,0.047,0.052,0.098,0.068,0.154c0.031-0.094,0.088-0.172,0.18-0.18
	c0.119-0.007,0.229,0.112,0.237,0.261c0.013,0.156-0.086,0.287-0.201,0.298c-0.093,0.005-0.159-0.065-0.202-0.155v0.001
	c-0.008,0.057-0.024,0.11-0.047,0.16c-0.002,0.004-0.004,0.007-0.005,0.01c-0.023,0.045-0.048,0.086-0.08,0.121
	c-0.015,0.016-0.03,0.027-0.046,0.04c-0.025,0.021-0.049,0.045-0.079,0.06c-0.048,0.025-0.103,0.046-0.162,0.049
	c-0.257,0.018-0.482-0.199-0.505-0.484C225.108,103.791,225.306,103.545,225.563,103.524z M227.693,107.521
	c0.353-0.539,0.548-1.176,0.505-1.86c-0.071-1.101-0.739-2.017-1.676-2.498c1.656,0.223,2.898,1.185,2.898,1.185
	S229.24,106.088,227.693,107.521z M220,108.663c0,0,0.549-1.961,1.961-3.573c-0.096,0.328-0.148,0.674-0.124,1.032
	c0.105,1.613,1.468,2.828,3.114,2.845C222.49,109.542,220,108.663,220,108.663z"
      />
      <path
        d="M200.729,114.009c-0.064,0-0.125-0.01-0.189-0.005c-0.535,0.04-1.021,0.209-1.447,0.458
	c-0.174,0.073-0.347,0.153-0.515,0.245c-3.078,1.67-4.133,5.84-4.133,5.84s3.935,1.461,7.01-0.21c0.63-0.343,1.17-0.794,1.641-1.288
	c0.052-0.055,0.103-0.11,0.15-0.166c1.706-1.903,2.338-4.387,2.338-4.387S203.224,113.623,200.729,114.009 M201.701,115.942
	c-0.092,0.008-0.158-0.057-0.201-0.142c-0.034,0.227-0.202,0.408-0.424,0.425c-0.258,0.019-0.482-0.189-0.504-0.47
	c-0.02-0.276,0.171-0.52,0.43-0.54c0.222-0.016,0.414,0.142,0.48,0.361c0.031-0.091,0.087-0.167,0.178-0.174
	c0.13-0.009,0.231,0.105,0.243,0.254C201.913,115.807,201.83,115.933,201.701,115.942z M200.422,115.523
	c-0.02,0.078-0.08,0.131-0.139,0.122c-0.05-0.01-0.079-0.086-0.062-0.165c0.019-0.076,0.076-0.135,0.133-0.123
	C200.41,115.368,200.434,115.444,200.422,115.523z M201.34,120.166c-2.733,1.486-6.215,0.195-6.215,0.195s0.708-2.788,2.686-4.492
	c-0.191,0.426-0.294,0.891-0.258,1.381c0.125,1.664,1.657,2.905,3.435,2.772c0.323-0.024,0.626-0.106,0.914-0.213
	C201.723,119.938,201.538,120.06,201.34,120.166z M202.52,114.49c1.422,0.099,2.496,0.494,2.496,0.494s-0.321,1.246-1.122,2.58
	c0.052-0.255,0.085-0.519,0.064-0.791C203.888,115.802,203.322,114.981,202.52,114.49z"
      />
      <path
        d="M217.159,118.537c0.804,2.666,5.242,6.298,5.242,6.298s-5.425-6.988-5.612-11.279
	C216.602,109.268,216.353,115.868,217.159,118.537"
      />
      <path
        d="M223.854,128.943c1.6-1.234,3.674-6.875,2.067-3.873c-1.604,3.007-5.45,3.877-9.924,5.449
	C211.524,132.092,222.25,130.177,223.854,128.943"
      />
      <path
        d="M212.793,136.398c-0.828,0.754,3.092,2.062,5.08,1.961c1.998-0.1,11.453-3.791,12.323-6.229
	c0.504-1.408-0.142-2.967-0.683-1.605c-0.925,2.323-9.039,5.561-12.625,6.321C213.298,137.604,213.294,135.939,212.793,136.398"
      />
      <path
        d="M232.683,129.32c-0.033-0.005-0.046-0.012-0.071-0.017c0.022,0.048,0.041,0.109,0.056,0.18
	C232.732,129.397,232.76,129.338,232.683,129.32"
      />
      <path
        d="M229.454,133.948c-2.822,2.687-10.298,4.892-10.482,4.897c-0.178,0.006,2.501,1.012,5.31-0.189
	c2.097-0.891,4.272-2.164,4.886-2.861C230.15,134.764,229.698,133.716,229.454,133.948"
      />
      <path
        d="M205.328,119.323c-0.734,1.456-7.474,3.769-7.474,3.769s6.345-1.232,6.889-1.897
	C205.286,120.535,206.06,117.871,205.328,119.323"
      />
      <path
        d="M227.674,110.888c1.879-0.822,3.953-4.657,1.1-1.299c-0.628,0.741-6.669,1.697-6.669,1.697
	S225.535,111.817,227.674,110.888"
      />
      <path
        d="M192.807,118.62c-1.553,3.462,4.379-5.575,7.446-5.505c10.56,0.242,2.105-0.71-0.168-0.607
	C197.812,112.612,192.807,118.62,192.807,118.62"
      />
      <path
        d="M231.205,101.95c-1.039-0.599-7.074-1.459-8.915-0.185c-0.509,0.354-1.003,1.01-1.452,1.769
	c1.348-1.417,3.574-2.377,7.281-1.9C229.939,102.065,231.993,102.404,231.205,101.95"
      />
      <path
        d="M220.756,103.922c-1.291,1.356-1.739,3.151-1.925,4.436C218.831,108.358,219.645,105.802,220.756,103.922"
      />
      <path
        d="M360.013,148.387c2.881,1.713,5.797,2.062,6.771,0.887c-0.615,0.695-2.282,0.595-4.254-0.166
	c0.95-0.301,1.761-0.982,2.142-1.984c0.227-0.598,0.255-1.221,0.153-1.814c1.676,1.443,2.568,2.942,2.109,3.801
	c0.007-0.01,0.016-0.016,0.021-0.021c0.795-1.416-1.025-4.027-4.058-5.833c-3.042-1.8-6.151-2.116-6.946-0.698
	c-0.006,0.008-0.004,0.021-0.006,0.024c0.488-0.825,2.173-0.776,4.194-0.03c-0.89,0.319-1.65,0.981-2.016,1.94
	c-0.237,0.614-0.261,1.259-0.139,1.874c-1.496-1.307-2.346-2.652-2.122-3.514C355.414,144.309,357.16,146.693,360.013,148.387"
      />
      <path
        d="M343.768,149.538c-1.482,2.75-0.685,3.767-0.685,3.767s-0.25-2.296,5.197-10.298
	C353.728,134.999,345.258,146.782,343.768,149.538"
      />
      <path
        d="M344.462,160.963c0.314-0.533-2.391,0.961-3.205,0.614c-1.899-0.802-4.166-2.233-5.888-3.41
	c-1.574-1.075-2.62-3.021-2.62-3.021s0.678,2.24,2.1,3.597c1.609,1.539,4.072,3.327,7.243,3.969
	C341.669,162.496,343.744,162.176,344.462,160.963"
      />
      <path
        d="M334.602,168.679c3.588,1.858,10.656,1.947,9.455,1.446c-8.622-3.581-11.994-4.749-17.395-10.768
	C320.686,152.693,330.414,166.514,334.602,168.679"
      />
      <path
        d="M326.458,161.195c0,0,0.317,3.783,2.348,6.175c3.156,3.718,1.333,3.021,7.413,4.315
	c1.229,0.266,4.881-0.834,4.881-0.834s-4.231-0.344-7.804-2C329.718,167.2,326.458,161.195,326.458,161.195"
      />
      <path
        d="M335.763,119.815c-2.931-1.688-5.121-2.603-6.021-2.57c-0.896,0.031-3.666,0.149-3.666,0.149
	s1.667,1.387,3.644,1.763c1.979,0.38,3.736,0.777,4.318,1.282c0.579,0.503,5.353,3.914,7.362,4.636
	c2.014,0.718,4.793,3.268,1.636-0.09C342.404,124.308,338.694,121.503,335.763,119.815"
      />
      <path
        d="M367.853,136.244c3.379,2.011,5.854,0.724,7.738,4.143c0.688,0.85,2.147,1.135,1.74-0.291
	c-0.406-1.434,0.231-1.104-1.813-2.601c-2.044-1.501-1.077-1.763-5-2.274c-3.918-0.509-11.115-3.222-11.51-3.064
	c-0.397,0.154-2.551-0.863-1.745,1.18C358.069,135.375,364.232,134.1,367.853,136.244"
      />
      <path
        d="M364.184,142.391c1.247,1.115,5.237,5.618,5.237,5.618s-3.867-5.577-5.986-6.675
	c-2.128-1.089-6.604-1.346-6.604-1.346S362.946,141.266,364.184,142.391"
      />
      <path
        d="M358.022,149.896c0.325,0.246,0.653,0.431,0.983,0.508C370.051,152.995,361.827,151.141,358.022,149.896"
      />
      <path
        d="M354.739,145.42c-0.205-0.423-0.326-0.654-0.326-0.654S354.533,145.023,354.739,145.42"
      />
      <path
        d="M356.368,149.17c0.06,0.157,0.729,0.423,1.654,0.727c-1.396-1.051-2.699-3.348-3.283-4.477
	C355.089,146.148,355.691,147.449,356.368,149.17"
      />
      <path
        d="M339.815,131.364c0,0,0.2,2.441-0.043-1.154c-0.241-3.476-18.648-7.734-7.849-4.329
	C335.028,126.861,338.636,128.479,339.815,131.364"
      />
      <path
        d="M335.647,136.641c0,0-1.224,0.078-2.655-0.05C334.838,137.163,335.647,136.641,335.647,136.641"
      />
      <path
        d="M332.992,136.591c-0.767-0.235-1.705-0.655-2.846-1.386c-1.326-0.848-2.077-1.498-2.504-1.993
	c0.267,0.606,0.693,1.345,1.413,2.078C329.879,136.127,331.542,136.462,332.992,136.591"
      />
      <path
        d="M327.643,133.212c-0.338-0.774-0.407-1.33-0.407-1.33S326.812,132.248,327.643,133.212"
      />
      <path
        d="M363.128,169.33c1.612-2.12,2.673-3.997,3.226-5.516C366.905,162.293,365.093,165.499,363.128,169.33"
      />
      <path
        d="M313.328,149.42c-0.012,0.059-0.022,0.238-0.027,0.501C313.431,149.281,313.443,149.021,313.328,149.42"
      />
      <path
        d="M352.953,178.871c-1.694,2.976-3.061,5.667-2.423,5.392c1.445-0.625-1.634,4.768,7.225,4.294
	c-5.293-1.047-3.12-5.761-4.419-5.121c-0.854,0.415,1.668-4.955,3.979-9.462C356.167,175.49,354.724,177.125,352.953,178.871"
      />
      <g>
        <defs>
          <polygon
            id="SVGID_1_"
            points="-3.94,35.003 532.25,35.003 536.25,263.94 0.06,263.94 		"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_" overflow="visible" />
        </clipPath>
        <path
          clip-path="url(#SVGID_2_)"
          d="M305.032,156.982c-1.12-1.172-1.12,1.451-0.504,5.529
		c0.104-0.635,0.364-0.83,0.835-0.408c4.685,4.266,7.348-3.656,7.885-5.899c0-0.214,0.007-0.366,0.024-0.413
		c0.107-0.33,0.1-0.111-0.024,0.413c-0.036,2.795,1.095,18.968,4.419,23.784c1.878,2.723,4.915,3.584,10.239,4.416
		c2.619,0.409,11.389-2.354,17.549-4.309c3.526-0.877,6.478-1.765,7.669-2.26c3.521-1.463,5.13-6.035,8.334-9.21
		c3.603-3.575,12.068-19.731,12.068-19.731s-11.936,16.125-14.36,19.241c-2.421,3.107-7.551,7.874-10.257,9.353
		c-2.705,1.478-18.206,4.613-18.622,4.517c-1.514-0.354-2.158-0.402-2.583-0.469c-0.314-0.101-0.608-0.213-0.885-0.331
		c-0.334-0.201-0.809-0.536-1.671-1.131c-0.601-0.417-1.106-0.795-1.57-1.164c-1.013-1.15-1.73-2.508-2.626-3.982
		c-0.718-1.181-1.458-2.766-2.183-4.565c-1.875-6.572-5.414-20.704-4.937-21.532c0.578-0.993-0.287-0.6-0.404,1.021
		c-0.132-0.191,0.162,1.94,0.662,5.043c-0.496-2.254-0.79-4.058-0.79-4.974C312.759,152.634,310.042,162.199,305.032,156.982"
        />
        <path
          clip-path="url(#SVGID_2_)"
          d="M400.164,91.77c0.509-1.344-6.746-12.36-6.19-16.92
		c0.526-9.573,4.826-11.891,4.877-12.242c0.032-0.017,0.032-0.025,0.006-0.031c0.014-0.101-0.023-0.043-0.048,0.009
		c-0.313,0.043-10.481,5.207-12.149,4.396c-1.666-0.811-3.75-2.772-3.75-2.772s-4.429-5.565-0.558-16.273
		c0.951,0.562,2.046,0.888,3.213,0.892c3.629,0.011,6.567-3.076,6.567-6.892c-0.004-3.815-2.947-6.921-6.575-6.934
		c-3.636-0.011-6.578,3.075-6.574,6.891c0.003,2.535,1.304,4.749,3.24,5.961c-0.932,1.967-4.549,10.458-11.657,10.359
		c-3.384-0.047-2.139,0.72-5.31-1.33c-3.173-2.05-2.06-10.375-2.189-10.709c0.016-0.023,0.013-0.039-0.014-0.027
		c0-0.005,0.01-0.045,0.025-0.105c0.533,0.262,1.122,0.416,1.742,0.417c2.37,0.008,4.285-2.087,4.281-4.68
		c0-2.593-1.917-4.701-4.285-4.709c-2.369-0.007-4.285,2.087-4.285,4.682c0.004,1.904,1.044,3.54,2.531,4.281
		c-0.017,0.051-0.03,0.095-0.048,0.149c-3.164,9.685-4.583,10.658-7.49,11.931c-2.907,1.275-17.807,4.654-17.581,4.654
		c3.59-0.013,24.221-1.995,34.892,2.47C386.015,70.76,400.628,90.268,400.164,91.77 M398.143,63.253l-7.524,7.363
		c-0.005-0.003-0.008-0.011-0.008-0.011L398.143,63.253z M381.692,42.01c-0.003-2.981,1.077-4.057,3.816-4.048
		c2.742,0.01,3.12,0.837,3.122,3.817c0.006,2.979-0.497,3.709-3.238,3.701C382.653,45.47,381.695,44.987,381.692,42.01z
		 M382.032,48.515l-5.083,12.258c-0.009-0.004-0.019-0.009-0.026-0.012L382.032,48.515z M362.148,41.779
		c-0.005-2.183,1.084-2.551,2.773-2.545c1.691,0.005,1.738-0.101,1.737,2.082c0.001,2.182-0.562,3.14-3.239,2.544
		C362.401,43.215,362.148,43.338,362.148,41.779z M360.221,56.346l2.561-9.248C362.781,47.098,360.222,56.346,360.221,56.346z"
        />
      </g>
      <path
        d="M402.978,64.328c2.362,0.007,4.279-2.088,4.28-4.681c-0.002-2.593-1.92-4.701-4.289-4.709
	c-2.368-0.008-4.286,2.089-4.283,4.681C398.686,62.212,400.606,64.319,402.978,64.328 M403.087,57.506
	c1.582,0.006,1.621,0.411,1.62,2.314c0.004,1.901-0.499,2.203-2.081,2.197c-1.583-0.005-1.276-0.411-1.272-2.312
	C401.35,57.802,401.506,57.501,403.087,57.506z"
      />
      <path d="M325.196,85.054C325.269,84.914,325.273,84.886,325.196,85.054" />
      <path
        d="M316.197,83.106c-0.197,0.634-3.515,4.721-2.702,5.9c0.811,1.179,4.907,4.219,4.582,6.349
	c-0.327,2.13-6.714,3.969-7.252,6.487c-0.132,1.497,2.01,7.533,1.415,9.314c-0.59,1.778-2.073,6.224-7.339,5.253
	c0.401,0.591,13.016,7.706,11.323,9.32c-1.692,1.611-3.03,1.465-5.341,2.873c-2.312,1.412-4.016,6.123-1.597,7.8
	c2.419,1.682,1.883,2.588,1.883,2.588s-0.658,5.748,1.196,6.355c1.857,0.604,0.84,3.157,2.717,0.788
	c1.877-2.368,0.401-5.978,1.449-6.553c1.042-0.571,0.659-4.136,0.445-6.972c-0.211-2.833,3.76-17.75,5.302-19.723
	c1.544-1.973,8.632-2.5,10.722-5.26c2.093-2.758,1.03-4.042,2.591-7.378c1.566-3.338,3.896-4.503,5.754-3.897
	c1.858,0.605,0.662,4.039,2.789,4.996c2.123,0.957,8.245-2.843,11.589-3.339c3.35-0.498,4.885-0.983,5.291-3.615
	c0.405-2.632,0.46-1.892-1.218-3.379c-1.681-1.485-1.394,0.722-4.119-3.41c-2.725-4.135,0.104-3.477-2.196-6.775
	c-2.3-3.299-2.818-3.756-6.87-4.693c-4.06-0.937-15.812,0.308-18.509,1.377c-2.697,1.067-3.007,8.277-4.978,7.802
	c-1.965-0.472-3.827-1.076-3.36-2.97c0.466-1.893,4.394-1.071,4.197-2.047c-0.196-0.977-9.639-2.876-10.295-1.959
	C313.009,79.254,316.393,82.472,316.197,83.106"
      />
      <g>
        <defs>
          <polygon
            id="SVGID_3_"
            points="-3.94,35.003 532.25,35.003 536.25,263.94 0.06,263.94 		"
          />
        </defs>
        <clipPath id="SVGID_4_">
          <use xlink:href="#SVGID_3_" overflow="visible" />
        </clipPath>
        <path
          clip-path="url(#SVGID_4_)"
          d="M396.004,100.215c-1.867,0.077-4.224-2.288-4.711-6.464
		c-1.783,1.187-4.372,0.511-1.285-1.02c3.09-1.529,1.501-3.394,0.781-4.146c-0.722-0.751-2.795,3.058-4.278,2.673
		c-1.475-0.386,0.255-1.506-1.268-2.508s-4.409-2.398-4.517-3.876c-0.109-1.48,3.013-0.906,2.007-2.256
		c-1.008-1.349-1.729,1.181-3.618,0.145c-1.885-1.037-1.963-2.084-3.367-3.095c-1.404-1.011-3.279-0.251-3.197,0.857
		c0.082,1.109,0.187,3.332,0.834,3.16c0.655-0.173,2.583-1.059,2.583-1.059s-1.536-0.321-0.824-1.302
		c0.716-0.983,1.41,0.267,1.406,1.009c-0.007,0.745,1.391,1.632-1.518,2.343c-2.908,0.714-2.204,0.413-3.956-1.191
		c-1.752-1.605-10.795-3.844-12.964-3.745c-2.167,0.101-2.638,1.064-3.038,1.341c-0.403,0.279-0.006,2.417,0.228,3.082
		c0.228,0.664,1.763,3.399,2.73,4.257c0.969,0.857,3.048,3.614,4.149,3.78c1.099,0.166,0.177,3.208-1.232,4.552
		c-1.412,1.343-3.346,1.301-3.608,1.878c-0.26,0.579,0.058,0.739,0.838,1.549c0.787,0.808,2.61,13.933,4.201,14.186
		c1.59,0.253,5.926-3.167,5.037-2.11c-0.888,1.057-1.852,2.739-1.92,3.366c-0.079,0.627,14.353,16.03,14.353,16.03
		s-1.673,0.992-1.075,1.69c0.598,0.7,1.267-0.032,2.124,0.959c0.857,0.988,0.525,9.502-0.948,9.984
		c-1.479,0.481-3.377-2.353-4.391-2.153c-1.01,0.2,1.224,4.247,0.487,6.532c-0.739,2.287-3.236,2.782-3.756,4.741
		c-0.518,1.959-1.506,6.556-2.826,7.521c-1.316,0.966,8.449-9.485,12.84-11.136c4.394-1.646,1.726,10.758,4.322-6.286
		c2.604-17.044,18.236-11.826,13.927-13.488c-0.232-0.091-0.455-0.169-0.671-0.244c0.355-1.01,0.627-1.955,0.772-2.764
		c0.681-3.831-8.646-16.333-6.946-16.955c1.711-0.624,2.752-2.868,4.425-3.922c1.674-1.053-0.967,4.038-0.844,4.895
		c0.125,0.858,5.103-2.858,5.803-4.768c0.708-1.912,0.29-6.715,0.778-7.493c0.479-0.78,1.4-1.406,1.958-1.262
		c0.555,0.144-0.38,1.392-0.354,1.762c0.025,0.368,0.555,0.143,1.414-0.478c0.86-0.621,0.086-3.723-0.795-4.153
		c-0.873-0.431-3.105,2.894-4.527,4.054C400.142,99.845,397.869,100.138,396.004,100.215 M375.254,164.945
		c0.225,0.121,0.522-0.127,0.862-0.611 M398.387,129.332c-0.197-0.045-0.383-0.084-0.556-0.116c0.505-1.585,0.639-3.005,0.639-3.005
		S398.692,127.017,398.387,129.332z"
        />
      </g>
      <g>
        <defs>
          <polygon
            id="SVGID_5_"
            points="-3.94,35.003 532.25,35.003 536.25,263.94 0.06,263.94 		"
          />
        </defs>
        <clipPath id="SVGID_6_">
          <use xlink:href="#SVGID_5_" overflow="visible" />
        </clipPath>
        <path
          clip-path="url(#SVGID_6_)"
          d="M340.604,208.056c-5.312,1.971-2.548,0.865-9.913,0.739
		c-7.367-0.123-25.589-12.038-28.314-16.786c-0.455-0.795-0.861-2.069-1.216-3.636c5.027,5.716,20.946,16.137,27.771,16.276
		c7.533,0.152,4.707,1.32,10.137-0.755c5.431-2.074,27.355-13.674,27.25-15.098c-0.106-1.425,7.849,0.781,13.504,8.247
		c5.647,7.46,14.665-6.936,12.473-9.478c-8.368-9.713-2.084,31.71-17.216,25.326"
        />
      </g>
    </g>
  </svg>
</div>
