<div class="home-wrapper">
  <div class="grid-container">
    <div class="grid-header">
      <app-header></app-header>
    </div>
    <div class="grid-logo">
      <app-logo></app-logo>
    </div>
    <div class="grid-icons">
      <div class="grid-icon-figurentheater">
        <a href="https://www.petruschka.ch">
          <app-figurentheater-icon
            class="item item-1"
          ></app-figurentheater-icon>
        </a>
      </div>
      <div class="grid-icon-schlosswochen">
        <a href="https://www.schlosswochen.ch">
          <app-schlosswochen-icon class="item item-2"></app-schlosswochen-icon>
        </a>
      </div>
      <div class="grid-icon-spielgruppe">
        <a href="https://www.kindundnatur.ch">
          <app-spielgruppe-icon class="item item-3"></app-spielgruppe-icon>
        </a>
      </div>
    </div>
  </div>
</div>
