<div class="wrapper">
  <svg
    id="svg-header-text"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 141.73 22.35"
    xml:space="preserve"
  >
    <g>
          <path class="svg-type-font svg-text-173438"
                d="M3.703,3.656c0.761,0-0.543,8.091,0.378,8.655s6.429-10.138,7.654-7.746s-6.514,9.028-6.65,9.721
	c-0.24,1.223,9.602,6.996,6.516,7.87c-2.975,0.843-6.818-6.928-7.25-6.502c-0.433,0.425,0.401,6.152-1.054,6.44
	C0.085,22.73,0.543,3.656,3.703,3.656z"
          />
      <path class="svg-type-font svg-text-4CAB27"
            d="M14.041,3.677c1.836-0.088,2.041,18.038,0.108,18.501C12.461,22.582,10.432,3.85,14.041,3.677z"
      />
      <path class="svg-type-font svg-text-D388C1"
            d="M17.49,4.249c1.504-2.083,4.351,11.727,5.774,11.727s-1.792-11.032,0.48-11.608s1.822,17.419,0.831,17.747
	c-0.99,0.327-4.039-8.854-5.327-9.026s-0.83,9.472-2.044,9.294C15.99,22.203,15.986,6.331,17.49,4.249z"
      />
      <path class="svg-type-font svg-text-AC002E"
            d="M35.287,12.869c-1.122-4.115-6.504-9.477-7.756-9.332c-1.252,0.146-1.383,18.573,0.365,18.949
	C29.643,22.862,36.408,16.982,35.287,12.869z M32.438,14.503c-0.704,2.085-2.76,5.992-3.842,5.229
	c-0.677-0.477-0.055-11.492,1.027-11.512C30.706,8.201,33.007,12.816,32.438,14.503z"
      />
      <path class="svg-type-font svg-text-F31C80"
            d="M36.917,4.696c1.163-1.163,6.424-0.426,6.796,0.625s-4.045-0.276-5.025,0.761s-1.234,5.804-0.377,6.109
	s3.18-0.79,4.144,0.654s-3.266,0.979-3.856,1.569s-0.59,4.652-0.177,5.763s4.929,0.088,5.442,1.162s-6.395,1.448-6.859,0.986
	C36.541,21.863,35.754,5.859,36.917,4.696z"
      />
      <path class="svg-type-font svg-text-5A9C36"
            d="M46.943,12.193c0.243-0.779,5.026,0.395,6.351-0.654c0.623-0.525,0.662-5.063,0.042-5.958
	c-0.622-0.894-6.96-1.852-8.016-1.204c-1.055,0.647-1.062,18.117,0.435,18.117s0.376-6.879,0.728-7.34
	c0.352-0.463,6.784,7.682,8.547,6.908S46.7,12.972,46.943,12.193z M46.64,6.652c0.294-1.061,3.894-0.598,4.321,1.015
	c0.958,3.622-1.551,2.94-3.526,3.022C46.503,10.667,46.335,7.749,46.64,6.652z"
      />
      <path class="svg-type-font svg-text-FE230B"
            d="M68.268,4.835c1.902,0-0.602,13.462,1.109,13.462c1.71,0,4.902-1.896,5.101-2.49
	c0.201-0.594-0.451-9.953,1.133-11.536s2.337,17.975,1.131,18.018s-1.164-3.431-1.908-4.175s-5.78,4.58-6.654,3.706
	S66.365,4.835,68.268,4.835z"
      />
      <path class="svg-type-font svg-text-C12D93"
            d="M79.854,4.022c1.646-0.499-0.202,15.753,2.19,15.753s6.144,0.471,5.384,1.568
	c-0.76,1.1-7.257,0.609-8.062,0.32S78.209,4.521,79.854,4.022z"
      />
      <path class="svg-type-font svg-text-42238C"
            d="M81.954,5.132c0-1.82,10.303-1.977,13.149-0.641s-4.434,1.502-4.925,2.943s1.101,14.593-0.883,14.535
	S88.082,9.092,88.082,7.702S81.954,6.952,81.954,5.132z"
      />
      <path class="svg-type-font svg-text-52AE26"
            d="M120.092,20.007c-0.488,0.083-0.978,1.476-0.677,1.723c0.301,0.246,1.242,0.163,1.694,0
	c0.452-0.164,0.602-0.206,0.753-0.657s0.075-0.286-0.301-0.819C121.184,19.72,120.582,19.925,120.092,20.007z"
      />
      <path class="svg-type-font svg-text-3E97A9"
            d="M124.83,3.973c-8.001,2.063-2.307,17.02,0.119,17.689s4.506-0.783,1.812-1.95s-3.707-0.533-3.918-5.185
	s0.461-8.025,2.776-7.452s0.383,2.502,1.632,2.502S128.188,3.973,124.83,3.973z"
      />
      <path class="svg-type-font svg-text-2A3796"
            d="M130.71,22.026c2.164-0.088,1.024-6.177,1.758-6.937c0.731-0.76,2.917-0.984,3.247,0
	c0.329,0.983-0.662,6.792,1.062,7.017s1.872-15.856,0.085-17.644s-0.488,6.705-1.22,7.017c-0.732,0.313-3.186,1.106-3.772,0.48
	c-0.585-0.625,0.366-8.771-1.464-7.944S128.546,22.114,130.71,22.026z"
      />
      <path class="svg-type-font svg-text-173438"
            d="M58.036,3.587c0.761,0-0.543,8.091,0.378,8.655s5.429-10.138,6.654-7.746s-5.514,9.028-5.65,9.721
	c-0.24,1.222,9.602,6.996,6.516,7.871c-2.975,0.842-6.818-6.928-7.25-6.502c-0.433,0.424,0.401,6.152-1.054,6.439
	C54.418,22.662,54.876,3.587,58.036,3.587z"
      />
      <path class="svg-type-font svg-text-50B14F"
            d="M97.356,3.835c1.902,0-0.602,14.462,1.109,14.462c1.71,0,4.902-1.896,5.101-2.49
	c0.201-0.594-0.451-9.953,1.133-11.536s2.337,17.975,1.131,18.018s-1.164-3.431-1.908-4.175s-5.78,4.58-6.654,3.706
	S95.454,3.835,97.356,3.835z"
      />
      <path class="svg-type-font svg-text-5A9C36"
            d="M110.131,11.653c0.242-0.779,5.025,0.395,6.351-0.654c0.623-0.525,0.662-5.063,0.042-5.958
	c-0.623-0.894-6.961-1.852-8.016-1.204s-1.062,18.118,0.435,18.118s0.376-6.879,0.728-7.34c0.352-0.463,6.784,7.682,8.548,6.909
	S109.887,12.432,110.131,11.653z M109.827,6.113c0.294-1.061,3.894-0.598,4.321,1.015c0.958,3.622-1.552,2.94-3.526,3.022
	C109.69,10.127,109.523,7.209,109.827,6.113z"
      />
    </g>

  </svg>
</div>
